import {
    GET_OFFICES_BY_USER_SOS_REQUEST,
    GET_OFFICES_BY_USER_SOS_SUCCESS,
} from './SketchOSOfficeByUserActions';

const defaultState = {
    sosUserOffices: {},
    isFetching: false,
};

function SketchOSOfficeByUserReducer(state = defaultState, action) {
    switch (action.type) {
    case GET_OFFICES_BY_USER_SOS_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case GET_OFFICES_BY_USER_SOS_SUCCESS:
    {
        const { userId } = action;
        const { offices } = action;
        const newUserIdOffices = {};
        newUserIdOffices[userId] = offices;
        return Object.assign({}, state, {
            sosUserOffices: Object.assign(state.sosUserOffices, newUserIdOffices),
            isFetching: false,
        });
    }
    default:
        return state;
    }
}

export default SketchOSOfficeByUserReducer;

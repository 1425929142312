import React from 'react';
import PropTypes from 'prop-types';
import { MAP } from 'react-google-maps/lib/constants';
import LocalStorageWrapper from '../../../../lib/LocalStorageWrapper';
import featureFlags from '../../../../lib/FeatureFlags.ts';

export function withParcelLines(WrappedComponent) {
    class ParcelLines extends React.Component {
        static contextTypes = {
            [MAP]: PropTypes.object,
        };

        constructor(props) {
            super(props);

            this.state = {
                mapInstance: null,
                mapsApi: null,
                parcelScriptTag: null,
                sik: null,
                Dmp: null,
                jwtToken: LocalStorageWrapper.getItem('accessToken'),
            };
        }

        componentDidMount() {
            if (!this.state.jwtToken || !featureFlags.parcelLines) return;
            this.setState({ mapInstance: this.context[MAP] });
            this.loadParcelScript();
        }

        componentWillUnmount() {
            if (this.state.parcelScriptTag === null) {
                return;
            }

            this.unloadParcelScript();
            this.setState({
                parcelScriptTag: null,
            });
        }

        getSik = (callback) => {
            if (this.state.sik) {
                callback(this.state.sik);
                return;
            }

            fetch(
                `${process.env.REACT_APP_ROOFSNAP_JWT_PROXY}/proxy/parcelAuth`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${this.state.jwtToken}`,
                    },
                }
            )
                .then((response) => response.json())
                .then((data) => callback(data.Response.Success.message))
                .catch(() => {
                    // TODO: Log failure
                });
        };

        getParcelData = () => {
            const resource = 'SS.Base.Parcels/Parcels';
            const { lat, lng } = this.props.center;
            const url = `getByGeometry.aspx?dataSource=${resource}&returnGeoType=1&returnFullWKT=true&geo=POINT(${lng} ${lat})&links=&nested=true&showSchema=false&fields=*`;
            this.parcelSdk().Env.Connections.SS.getJson(
                url,
                (json) => {
                    if (json.Response.Results.totalRecords <= 0) {
                        return;
                    }
                    this.renderParcels(json.Response.Results);
                },
                () => {
                    // TODO: Implement logging
                }
            );
        };

        handleParcelSdkInit = () => {
            // add the parcel layer to the map
            const sdk = this.parcelSdk();
            const layer = new sdk.Layer.TileLayer(
                'SS',
                'SS.Base.Parcels/ParcelTiles',
                {
                    minZoom: 16,
                    maxZoom: 20,
                }
            );
            this.state.mapInstance.overlayMapTypes.push(
                new this.state.mapsApi.ImageMapType(layer)
            );
            this.getParcelData();
        };

        handleParcelScriptReady = () => {
            this.getSik((sik) => {
                this.setState({ sik });
                window.Dmp.HostName = process.env.REACT_APP_SPATIAL_STREAM_URL;
                window.Dmp.Env.Connections.SS = new window.Dmp.Conn.Connection(
                    `${process.env.REACT_APP_SPATIAL_STREAM_URL}/InitSession.aspx`
                );
                window.Dmp.Env.Connections.SS.init(
                    sik,
                    this.handleParcelSdkInit
                );
                this.setState({
                    Dmp: window.Dmp,
                    mapsApi: window.google.maps,
                });
            });
        };

        parcelSdk = () => this.state.Dmp;

        loadParcelScript = () => {
            if (this.state.parcelScriptTag) {
                return;
            }
            const script = document.createElement('script');
            script.src = process.env.REACT_APP_PARCEL_SDK_URL;
            script.async = true;
            script.addEventListener('load', this.handleParcelScriptReady);
            document.body.appendChild(script);
            this.setState({
                parcelScriptTag: script,
            });
        };

        unloadParcelScript = () => {
            document.body.removeChild(this.state.parcelScriptTag);
            this.setState({
                parcelScriptTag: null,
            });
        };

        prepareParcelDataForRendering = (recordSet) => {
            let parcelRec = null;
            if (recordSet.Data && recordSet.Data.Row.length > 1) {
                [parcelRec] = recordSet.Data.Row;
            } else if (recordSet.Data) parcelRec = recordSet.Data.Row;
            return parcelRec;
        };

        renderParcels = (recordSet) => {
            const parcelRec = this.prepareParcelDataForRendering(recordSet);
            const keyField = 'LOCID';
            const keyValue = parcelRec[keyField];
            const SLD = 'PUBLIC_FOLDER.Styles/Identify.sld.xml';
            // add the server side highlight layer to highlight the clicked parcel
            const sdk = this.parcelSdk();
            const highlightLayer = new sdk.Layer.WMSLayer('parcelLayer', 'SS', {
                showField: keyField,
                showValues: keyValue,
                antiAlias: true,
                ignoreHoles: true,
            });
            highlightLayer.addChild('parcelLayer', recordSet.datasource, SLD, {
                zoomRange: { min: 10, max: 19 },
            });
            highlightLayer.setMap(this.state.mapInstance);
        };

        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    ParcelLines.propTypes = WrappedComponent.propTypes;

    return ParcelLines;
}

withParcelLines.propTypes = {
    WrappedComponent: PropTypes.element,
};

export default withParcelLines;

import React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import {
    Card,
    CardContent,
    Grid,
    Divider,
    Button,
    useMediaQuery,
    useTheme,
    Theme,
    Typography,
    Link,
} from '@material-ui/core';
import roofsnapTheme from '../../../../lib/theme';

interface CreateSketchOrderCompleteProps extends WithStyles<typeof styles> {
    isFreeAccount: boolean;
    history: any;
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            [theme.breakpoints.up('md')]: {
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
        },
        card: {
            maxWidth: 629,
            [theme.breakpoints.down('sm')]: {
                margin: theme.spacing(2),
                marginTop: theme.spacing(4),
                bottom: theme.spacing(2),
                position: 'relative',
            },
        },
        cardContent: {
            [theme.breakpoints.up('md')]: {
                padding: '50px 80px 0px 80px',
            },
        },
        image: {
            width: '100%',
            maxHeight: 360,
        },
        imageContainer: {
            maxWidth: '530px',
            textAlign: 'center',
            margin: 'auto',
        },
        successText: {
            color: '#3EA645',
            paddingTop: theme.spacing(3),
            [theme.breakpoints.down('xs')]: {
                fontSize: 20,
            },
        },
        infoText: {
            paddingTop: theme.spacing(2),
        },
        middleDivider: {
            margin: `${theme.spacing(2)}px ${theme.spacing(11)}px`,
        },
        footerCardContent: {
            paddingBottom: '16px !important',
        },
        footer: {
            marginBottom: 0,
        },
        link: {
            color: roofsnapTheme.palette.roofsnapBlue[500],
        },
        newMeasurementOrderButton: {
            [theme.breakpoints.down('xs')]: {
                fontSize: 12,
            },
        },
        subscribeButton: {
            [theme.breakpoints.down('xs')]: {
                fontSize: 12,
            },
        },
        ordersButton: {
            [theme.breakpoints.down('xs')]: {
                fontSize: 12,
            },
        },
    });

const CreateSketchOrderComplete = (props: CreateSketchOrderCompleteProps) => {
    const { isFreeAccount, classes, history } = props;
    const rushed: boolean = history.location.state;
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const renderButtons = () => {
        if (isFreeAccount && mobile) {
            return (
                <React.Fragment>
                    <Grid item>
                        <Button
                            disableElevation
                            className={classes.newMeasurementOrderButton}
                            variant='outlined'
                            color='primary'
                            onClick={() => history.push('/orders/v2/create')}
                        >
                            New Measurement Order
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disableElevation
                            className={classes.subscribeButton}
                            variant='contained'
                            color='primary'
                            onClick={() => history.push('/settings/billing')}
                        >
                            Subscribe Now
                        </Button>
                    </Grid>
                </React.Fragment>
            );
        }
        if (mobile && !isFreeAccount) {
            return (
                <React.Fragment>
                    <Grid item>
                        <Button
                            disableElevation
                            className={classes.newMeasurementOrderButton}
                            variant='outlined'
                            color='primary'
                            onClick={() => history.push('/orders/v2/create')}
                        >
                            New Measurement Order
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disableElevation
                            className={classes.subscribeButton}
                            variant='contained'
                            color='primary'
                            onClick={() => history.push('/orders')}
                        >
                            View Orders
                        </Button>
                    </Grid>
                </React.Fragment>
            );
        }
        if (isFreeAccount && !mobile) {
            return (
                <React.Fragment>
                    <Grid item>
                        <Button
                            disableElevation
                            className={classes.subscribeButton}
                            variant='contained'
                            color='primary'
                            onClick={() => history.push('/settings/billing')}
                        >
                            Subscribe Now
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disableElevation
                            className={classes.newMeasurementOrderButton}
                            variant='outlined'
                            color='primary'
                            onClick={() => history.push('/orders/v2/create')}
                        >
                            New Measurement Order
                        </Button>
                    </Grid>
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                <Grid item>
                    <Button
                        disableElevation
                        id='view-orders-button'
                        className={classes.subscribeButton}
                        variant='outlined'
                        color='primary'
                        onClick={() => history.push('/orders')}
                    >
                        View Orders
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        disableElevation
                        className={classes.newMeasurementOrderButton}
                        variant='contained'
                        color='primary'
                        onClick={() => history.push('/orders/v2/create')}
                    >
                        New Measurement Order
                    </Button>
                </Grid>
            </React.Fragment>
        );
    };
    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                    <Grid
                        container
                        direction='column'
                        justifyContent='center'
                        alignItems='center'
                    >
                        <Grid className={classes.imageContainer} item>
                            <img
                                src='/assets/order-complete-icon.svg'
                                alt='artwork'
                                className={classes.image}
                            />
                        </Grid>
                        <Grid item>
                            {rushed ? (
                                <Typography
                                    id='order-placed-message'
                                    className={classes.successText}
                                    variant='h5'
                                >
                                    <i>Rush Order</i> has been placed!
                                </Typography>
                            ) : (
                                <Typography
                                    id='order-placed-message'
                                    className={classes.successText}
                                    variant='h5'
                                >
                                    Order has been placed!
                                </Typography>
                            )}
                        </Grid>
                        <Grid item>
                            {rushed ? (
                                <Typography
                                    id='order-description'
                                    className={classes.infoText}
                                    paragraph
                                    align='center'
                                    variant='body2'
                                >
                                    Rush Orders are typically completed within
                                    90 minutes, when placed Monday - Saturday,
                                    between 9am - 5pm ET. You will receive an
                                    email and your amendable project when your
                                    order is completed.
                                </Typography>
                            ) : (
                                <Typography
                                    id='order-description'
                                    className={classes.infoText}
                                    paragraph
                                    align='center'
                                    variant='body2'
                                >
                                    Measurement Orders are typically completed
                                    within 2 to 4 hours, when placed Monday -
                                    Saturday, between 9am - 5pm ET. Actual times
                                    may vary. You will receive an email and your
                                    dynamic project when your order is
                                    completed.
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider
                    className={mobile ? '' : classes.middleDivider}
                    variant={mobile ? 'fullWidth' : 'middle'}
                />
                <CardContent className={classes.footerCardContent}>
                    <Grid
                        container
                        direction='column'
                        justifyContent='center'
                        alignItems='center'
                    >
                        <Grid item>
                            {isFreeAccount ? (
                                <Typography
                                    paragraph
                                    variant='caption'
                                    align='center'
                                >
                                    Place another Measurement Order or{' '}
                                    <Link
                                        component={RouterLink}
                                        to='/settings/billing'
                                    >
                                        Subscribe
                                    </Link>{' '}
                                    for order discounts and to unlock RoofSnap's
                                    full suite of tools.
                                </Typography>
                            ) : (
                                <Typography
                                    paragraph
                                    variant='caption'
                                    align='center'
                                >
                                    Place another Measurement Order or view all
                                    placed orders.
                                </Typography>
                            )}
                        </Grid>
                        <Grid
                            container
                            item
                            justifyContent='center'
                            alignItems='center'
                            spacing={2}
                        >
                            {renderButtons()}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    const { organization } = state;
    const { isFreeAccount } = organization;
    return { isFreeAccount };
};

export default withRouter(
    connect(mapStateToProps)(withStyles(styles)(CreateSketchOrderComplete))
);

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';
import featureFlags from '../../lib/FeatureFlags';
import LoggerWrapper from '../../lib/Logger';
import FloatingActionButton from '../FloatingActionButton';
import CreateProjectDialog from './CreateProjectDialog';
import { getUsersOffices, handleMissingOffices } from '../../actions/Offices';
import { createProject } from '../../actions/ProjectActions';
import CreateProjectOptionDialog from './CreateProjectOptionDialog';
import { RoofSnapState } from 'lib/Models/RoofSnapState';
import ProjectCreateRequest from 'lib/Models/CreateProjectRequest';

const CreateProject = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const {
        organizationFeatures: { enableFastReports },
        organization: { isFreeAccount },
        currentUser: { userId, userName, organizationId },
        userOffices: { data: offices },
    } = useSelector((state: RoofSnapState) => state);

    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
    const [openCreateOptionDialog, setOpenCreateOptionDialog] =
        useState<boolean>(false);

    useEffect(() => {
        if (isEmpty(offices)) {
            dispatch(getUsersOffices(userId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCreateClick = (project: ProjectCreateRequest) => {
        const newProject = project;
        newProject.owner = userName;
        newProject.organizationId = organizationId;
        (dispatch(createProject(project)) as any).then((result: any) => {
            if (result && !result.error) {
                setOpenCreateDialog(false);
                try {
                    (window as any).Appcues.track('Created a Project');
                } catch (error) {
                    LoggerWrapper.captureException(error);
                }
                history.push(`/projects/${result.response.id}`);
            }
        });
    };

    const openCreateProjectDialog = () => {
        setOpenCreateOptionDialog(false);
        setOpenCreateDialog(true);
    };

    const goToCreateSketchOrder = () => {
        if (enableFastReports && featureFlags.newOrderFlow) {
            history.push('/orders/v2/create');
        } else {
            history.push('/orders/create');
        }
    };

    const handleClick = () => {
        try {
            (window as any).Appcues.track('Clicked Create');
        } catch (error) {
            LoggerWrapper.captureException(error);
        }
        setOpenCreateOptionDialog(true);
    };

    const handleCreateProjectCancel = () => {
        setOpenCreateDialog(false);
    };

    const handleCreateProjectOptionCancel = () => {
        setOpenCreateOptionDialog(false);
    };

    const handleMissingOfficesCallback = () => {
        if (offices && offices.length === 0) {
            dispatch(handleMissingOffices());
        }
    };

    return (
        <div>
            <CreateProjectDialog
                open={openCreateDialog}
                onCreateClick={handleCreateClick}
                onCancelClick={handleCreateProjectCancel}
                offices={offices}
                showMissingOfficesMessage={handleMissingOfficesCallback}
            />
            <CreateProjectOptionDialog
                open={openCreateOptionDialog}
                onCreateProjectClick={openCreateProjectDialog}
                onCreateSketchOrderClick={goToCreateSketchOrder}
                onCancelClick={handleCreateProjectOptionCancel}
                isFreeAccount={isFreeAccount}
            />
            <div>
                <FloatingActionButton
                    id='create-project-fab'
                    onClick={handleClick}
                    variant='extended'
                />
            </div>
        </div>
    );
};

export default CreateProject;

import {
    GET_PROJECT_REQUEST,
    GET_PROJECT_SUCCESS,
    GET_PROJECT_FAILURE,
    UPDATE_PROJECT_REQUEST,
    UPDATE_PROJECT_SUCCESS,
    UPDATE_PROJECT_FAILURE,
    UPDATE_PROJECT_MAP_IMAGE_REQUEST,
    UPDATE_PROJECT_MAP_IMAGE_SUCCESS,
    UPDATE_PROJECT_MAP_IMAGE_FAILURE,
    EXPORT_PROJECT_CSV_REQUEST,
    EXPORT_PROJECT_CSV_SUCCESS,
    EXPORT_PROJECT_CSV_FAILURE,
    CREATE_PROJECT_REQUEST,
    CREATE_PROJECT_SUCCESS,
    CREATE_PROJECT_FAILURE,
    CLONE_PROJECT_REQUEST,
    CLONE_PROJECT_SUCCESS,
    CLONE_PROJECT_FAILURE,
} from '../actions/ProjectActions';

function project(
    state = {
        currentProject: {},
        isFetching: false,
        offices: null,
    },
    action
) {
    switch (action.type) {
        case GET_PROJECT_REQUEST:
            return Object.assign({}, state, {
                currentProject: {},
                isFetching: true,
            });
        case GET_PROJECT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                currentProject: action.response,
            });
        case GET_PROJECT_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                currentProject: {},
                errorMessage: action.message,
            });
        case CREATE_PROJECT_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                isFetchingText: 'Creating project...',
            });
        case CREATE_PROJECT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
            });
        case CREATE_PROJECT_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
            });
        case UPDATE_PROJECT_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                updateMessage: '',
            });
        case UPDATE_PROJECT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
            });
        case UPDATE_PROJECT_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
            });
        case UPDATE_PROJECT_MAP_IMAGE_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                error: '',
                isFetchingText: 'Saving map image..',
            });
        case UPDATE_PROJECT_MAP_IMAGE_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                error: '',
                isFetchingText: '',
                currentProject: action.response,
            });
        case UPDATE_PROJECT_MAP_IMAGE_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                isFetchingText: '',
                error: 'We were unable to save this image, please try again',
            });
        case EXPORT_PROJECT_CSV_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case EXPORT_PROJECT_CSV_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                response: action.response,
            });
        case EXPORT_PROJECT_CSV_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
            });
        case CLONE_PROJECT_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case CLONE_PROJECT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                response: action.response,
            });
        case CLONE_PROJECT_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
            });
        default:
            return state;
    }
}

export default project;

import React from 'react';
import {
  Paper,
  Grid,
  Typography,
  createStyles,
  WithStyles,
  withStyles,
} from '@material-ui/core';

const styles = createStyles({
  deliveryTimeRoot: { border: '1px solid #f2f2f2', padding: '3em' },
  heading: {
    color: '#1F1F1F',
    fontSize: '1.2em',
    fontWeight: 'bold',
  },
  detailsContainer: {
    textAlign: 'center',
  },
  details: {
    color: '#9E9E9E',
  },
});

const DeliveryTime = ({ classes }: WithStyles<typeof styles>) => {
  return (
    <Paper elevation={3} className={classes.deliveryTimeRoot}>
      <Grid xs={12} container justifyContent='center'>
        <Grid className={classes.detailsContainer} item>
          <img src='/assets/DeliveryTimeIcon.svg' alt="Delivery Time Icon" />
          <Typography className={classes.heading}>Delivery Time</Typography>
          <Typography className={classes.details}>
            This order has a minimum 24-hour turnaround time.
          </Typography>
          <Typography className={classes.details}>
            Standard and Rush Order delivery not available.
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default withStyles(styles)(DeliveryTime);

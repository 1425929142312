import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    Theme,
    WithStyles,
    createStyles,
    withStyles,
} from '@material-ui/core/styles';
import { chain, orderBy } from 'lodash';

import {
    CREATE_BULK_SKETCH_ORDER_SUCCESS,
    createSketchOrders,
} from '../../../actions/SketchOrderActions';
import {
    addMarker,
    clearAddress,
    clearMarkers,
    geocodeAddress,
    removeMarker,
    updateMarker,
} from '../../../actions/SketchOsGeocodingActions';
import {
    getOrganizationOpenSketchOrders,
    getOrganizationsFreeSketchOrdersBalance,
} from '../../../actions/OrganizationFreeSketchOrdersActions';
import AddressVerification from '../../AddressVerification/AddressVerification';
import PaymentStep from './PaymentStep';
import Location from './Location';
import LoggerWrapper from '../../../lib/Logger';
import OrderSummary from './OrderSummary';
import PAYMENT_TYPE, { PaymentType } from '../../../lib/PaymentType';
import BuildingTypes from '../../../lib/BuildingTypes';
import MetalRoofOption from '../../../lib/MetalRoofOption';
import SketchOrderProjectDetails from './SketchOrderProjectDetails';

import { getOrganization } from '../../../actions/Organization';
import { getSketchOSOrdersByOrganization } from '../../../actions/SketchOSActions';
import { setReduxMessage } from '../../../actions/ReduxMessagesActions';
import { getTodaysSchedule } from '../../../lib/util/sketchTechAvailibilityHelper';
import { AllowedOrderTypes, OrderTypes } from '../../../lib/MeasurementOrders';
import { GeoPoint } from 'lib/Models/GeoPoint';
import SketchOrder from 'lib/Models/SketchOrder';
import User from 'lib/Models/User';
import Organization from 'lib/Models/Organization';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(1),
            },
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(3),
            },
        },
        paper: {
            padding: theme.spacing(2),
            margin: 'auto',
            maxWidth: 800,
        },
        backButton: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        stepTitle: {
            marginTop: theme.spacing(3),
        },
        stepSubtitle: {
            marginBottom: theme.spacing(3),
            color: 'rgba(0,0,0,0.54)',
        },
        stepBody: {
            marginTop: theme.spacing(3),
            color: 'rgba(0,0,0,0.54)',
        },
        stepper: {
            '@media only screen and (max-width: 600px)': {
                padding: '24px 0px',
            },
        },
        stepContainer: {
            '@media only screen and (max-width: 600px)': {
                padding: '0px 4px',
                minWidth: '40px',
            },
            '@media only screen and (max-width: 350px)': {
                padding: '0px 2px',
            },
        },
        stepLabelContainer: {
            '@media only screen and (max-width: 600px)': {
                padding: '0px 4px',
                minWidth: '40px',
            },
        },
        stepLabel: {
            '@media only screen and (max-width: 600px)': {
                fontSize: '10px',
            },
            '@media only screen and (max-width: 350px)': {
                fontSize: '8px',
            },
        },
    });

const stateDefaults = {
    center: {
        lat: 38,
        lng: -97,
    },
    zoom: 3,
};

const EXISTING_SKETCHOS_ORDER_MARKER_TYPE = Symbol(
    'EXISTING_SKETCHOS_ORDER_MARKER_TYPE'
);

const sleep = (ms: number) =>
    new Promise((resolve) => {
        setTimeout(resolve, ms);
    });

type CreateSketchOrderPageState = {
    address: string;
    city: string;
    region: string;
    postcode: string;
    country: string;
    projectName: string;
    ownerUserId: number;
    notes: string;
    center: GeoPoint;
    zoom: number;
    addressVerificationFormIsValid: boolean;
    paymentType: PaymentType | null;
    paymentValid: boolean;
    sketchReportType: AllowedOrderTypes;
    selectedBuildingType: BuildingTypes;
    selectedMetalPanelSize: number | undefined;
    selectedGutterSize: number | undefined;
    existingSketchOrders: any;
    showExistingOrderWarning: boolean;
    showInfoWindowForMarkerId?: number;
    showProjectDetailsHelperText: boolean;
    fetchingExistingOrders: boolean;
    rushed: boolean;
    isSketchTechAvailable: boolean;
    isMultipinOrder?: boolean;
    getNonce?: () => Promise<string>;
    rushStartTime?: Date;
    rushEndTime?: Date;
    currentServerTime?: Date;
    fetchingSchedule?: Boolean;
    activeStep: number;
};

type CreateSketchOrderPageProps = {
    address: string;
    city: string;
    region: string;
    postcode: string;
    country: string;
    currentUserId: number;
    defaultSelectedSketchOptions: string[];
    organizationId: number;
    markers: any[];
    selectedMetalOptions: string[];
    selectedGutterOptions: string[];
    sosDeliveryOfficeId: string[];
    freeSketchOrderBalance: number;
    openSketchOrders: number;
    rushOrdering: boolean;
    history: any;
    match: any;
    location: any;
    dispatch: (arg: any) => any | void;
    users: User[];
    organization: Organization;
} & WithStyles<typeof styles>;

class CreateSketchOrderPage extends Component<
    CreateSketchOrderPageProps,
    CreateSketchOrderPageState
> {
    includeGutters: boolean;
    constructor(props: CreateSketchOrderPageProps) {
        super(props);

        this.includeGutters = props.location.search.includes('gutter');

        this.state = {
            address: props.address,
            city: props.city,
            region: props.region,
            postcode: props.postcode,
            country: props.country,
            projectName: '',
            ownerUserId: props.currentUserId,
            notes: '',
            center: stateDefaults.center,
            zoom: stateDefaults.zoom,
            addressVerificationFormIsValid: false,
            paymentType: 'None',
            paymentValid: false,
            sketchReportType: props.match.params.sketchReportType,
            selectedBuildingType: BuildingTypes.None,
            selectedMetalPanelSize: undefined,
            selectedGutterSize: undefined,
            existingSketchOrders: {},
            showExistingOrderWarning: false,
            showInfoWindowForMarkerId: undefined,
            showProjectDetailsHelperText: false,
            fetchingExistingOrders: false,
            isSketchTechAvailable: false,
            rushed: false,
            activeStep: this.getActiveStep(),
        };
    }

    componentDidMount() {
        this.checkSketchTechAvailable();

        // check if we are NOT starting on step 0, and redirect browser to start.  this shouldn't happen
        // but if someone book marked or hit back potentially people could try to go to this.
        if (this.getActiveStep() !== 0) {
            this.props.history.push(
                `/orders/create/${this.props.match.params.sketchReportType}`
            );
            return;
        }

        this.props.dispatch(getOrganization());
        this.getFreeSketchOrders();
    }

    componentDidUpdate(prevProps: CreateSketchOrderPageProps) {
        if (
            (this.state.zoom === 3 && this.props.markers.length > 0) ||
            this.didAddressChange(prevProps)
        ) {
            this.centerAndZoomMarker(this.props.markers[0]);
        }
        this.updateSketchOrderAddress(prevProps);
    }
    onPaymentChange = (
        paymentType: PaymentType | null,
        paymentValid: boolean,
        getNonce: () => Promise<string>
    ) => {
        this.setState({
            paymentType,
            paymentValid,
            getNonce,
        });
    };
    onClickDialog = (proceed: boolean) => () => {
        this.setState({ showExistingOrderWarning: false });
        if (proceed) {
            this.setState({ activeStep: this.state.activeStep + 1 });
        }
    };
    getFreeSketchOrders() {
        this.props.dispatch(
            getOrganizationsFreeSketchOrdersBalance(this.props.organizationId)
        );
        this.props.dispatch(
            getOrganizationOpenSketchOrders(this.props.organizationId)
        );
    }
    getCloseSketchOrders = async () => {
        const statePromises = this.props.markers.map((m) => {
            const stateKey = `${m.coordinates.lat}_${m.coordinates.lng}`;
            // see if we already have results
            if (this.state.existingSketchOrders[stateKey]) {
                return Promise.resolve({
                    orders: this.state.existingSketchOrders[stateKey],
                    key: stateKey,
                });
            }
            return (async () => {
                const geo = m.coordinates;
                this.setState({ fetchingExistingOrders: true });
                const sketchOsResp = await this.props.dispatch(
                    getSketchOSOrdersByOrganization(
                        this.props.organizationId,
                        1,
                        geo.lat,
                        geo.lng,
                        25
                    )
                );
                this.setState({ fetchingExistingOrders: false });
                let orders = [];
                if (
                    sketchOsResp &&
                    sketchOsResp.response &&
                    sketchOsResp.response.resourceList
                ) {
                    orders = sketchOsResp.response.resourceList;
                }

                return {
                    key: stateKey,
                    orders,
                };
            })();
        });

        const existingOrderArrays = await Promise.all(statePromises);
        const newState = existingOrderArrays.reduce(
            (acc: { [key: string]: any[] }, currentVal) => {
                acc[currentVal.key] = currentVal.orders;
                return acc;
            },
            {}
        );

        this.setState({ existingSketchOrders: newState });
    };
    getExistingSketchOrdersFromState = () =>
        chain(Object.keys(this.state.existingSketchOrders))
            .map((key) => this.state.existingSketchOrders[key])
            .flatten()
            .uniqBy((o) => o.id)
            .value();

    getMarkersToShow = () => {
        const orders = this.getExistingSketchOrdersFromState();

        const existingOrderMarkers = orders.map((so) => ({
            coordinates: {
                lat: so.location.coordinates[1],
                lng: so.location.coordinates[0],
            },
            draggable: false,
            icon: {
                url: '/assets/sketch-order-map-icon_4.png',
                labelOrigin: { x: 11, y: 29 },
            },
            label: { text: so.address, color: 'white' },
            title: `${so.address}`,
            type: EXISTING_SKETCHOS_ORDER_MARKER_TYPE,
            id: so.id,
            infoWindowContent: this.getInfoWindowForMarker(so),
        }));

        return this.props.markers.concat(existingOrderMarkers);
    };
    getInfoWindowForMarker = (sketchOrder: SketchOrder) => {
        if (sketchOrder.id !== this.state.showInfoWindowForMarkerId) {
            return undefined;
        }

        return (
            <div style={{ margin: '5px' }}>
                <div>
                    <b>Address</b>: {sketchOrder.address}, {sketchOrder.city},{' '}
                    {sketchOrder.region}
                </div>
                <div>
                    <b>Requester</b>: {sketchOrder.requester?.userName}
                </div>
                <div>
                    <b>Status</b>: {sketchOrder.sketchOrderStatus}
                </div>
                <div>
                    <b>Type</b>: {sketchOrder.sketchReportType}
                </div>
                <div>
                    <a
                        href={`/orders/${sketchOrder.id}`}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        Click here to open
                    </a>
                </div>
            </div>
        );
    };
    getPaymentType = () => {
        switch (this.state.paymentType) {
            case PAYMENT_TYPE.USER:
                return 2;
            case PAYMENT_TYPE.ORGANIZATION:
                return 1;
            case PAYMENT_TYPE.NONE:
                return 0;
            default:
                throw Error('Unable to figure out a paymentType');
        }
    };
    getActiveStep = () => {
        if (!this.props.match.params.activeStep) {
            return 0;
        }
        return Number.parseInt(this.props.match.params.activeStep, 10);
    };
    centerAndZoomMarker = (marker: google.maps.Marker) => {
        this.setState({
            center: (marker as any).coordinates,
            zoom: 19,
        });
    };
    didAddressChange = (prevProps: CreateSketchOrderPageProps) =>
        prevProps.address !== this.props.address ||
        prevProps.city !== this.props.city ||
        prevProps.region !== this.props.region ||
        prevProps.postcode !== this.props.postcode ||
        prevProps.country !== this.props.country;
    updateSketchOrderAddress = (prevProps: CreateSketchOrderPageProps) => {
        if (prevProps.address !== this.props.address) {
            this.setState({ address: this.props.address });
        }
        if (prevProps.city !== this.props.city) {
            this.setState({ city: this.props.city });
        }
        if (prevProps.region !== this.props.region) {
            this.setState({ region: this.props.region });
        }
        if (prevProps.postcode !== this.props.postcode) {
            this.setState({ postcode: this.props.postcode });
        }
        if (prevProps.country !== this.props.country) {
            this.setState({ country: this.props.country });
        }
    };

    createSketchOrder = async (nonce: string) => {
        const sketchOrders: any[] = [];
        this.props.markers.forEach((marker, index) => {
            const multiProjectName =
                this.state.projectName === ''
                    ? index + 1
                    : `${this.state.projectName} - ${index + 1}`;
            const projectName =
                this.props.markers.length > 1
                    ? multiProjectName
                    : this.state.projectName;

            const baseOrder = {
                organizationId: this.props.organizationId,
                address: this.state.address,
                city: this.state.city,
                region: this.state.region,
                postcode: this.state.postcode,
                country: this.state.country,
                projectName,
                ownerUserId: this.state.ownerUserId,
                notes: this.state.notes,
                location: {
                    coordinates: [
                        marker.coordinates.lng,
                        marker.coordinates.lat,
                    ],
                    type: 'Point',
                },
                requesterUserId: this.props.currentUserId,
                sketchOptions: this.state.selectedBuildingType,
                metalRoofPanelSize: this.state.selectedMetalPanelSize,
                gutterSize: this.state.selectedGutterSize,
                officeId: this.props.sosDeliveryOfficeId,
                rushed: this.state.rushed,
            };
            const newSketchOrder = {
                ...baseOrder,
                sketchReportType: this.state.sketchReportType,
            };
            sketchOrders.push(newSketchOrder);
            if (this.includeGutters) {
                const gutterOrder = {
                    ...baseOrder,
                    sketchReportType: OrderTypes.Gutters,
                };
                sketchOrders.push(gutterOrder);
            }
        });

        const response = await this.props.dispatch(
            createSketchOrders(sketchOrders, this.getPaymentType(), nonce)
        );
        if (response.type !== CREATE_BULK_SKETCH_ORDER_SUCCESS) {
            this.props.dispatch(
                setReduxMessage('Error creating sketch order(s).')
            );
        } else {
            try {
                (window as any).Appcues.track('Created a Sketch Order');
            } catch (error) {
                LoggerWrapper.captureException(error);
            }
            // this is here to signal to google analytics that an order was successfully ordered
            this.props.history.push(
                `/orders/create/${this.state.sketchReportType}/complete`
            );
            await sleep(50); // HACK: here to let history trigger catch change and push to GA

            // now replace the complete url so users can NOT hit their back button and go back to this url and
            // trigger another GA order event

            this.props.history.replace(
                '/orders/create/complete',
                response.response[0].rushed
            );

            // clear out redux data
            this.props.dispatch(clearMarkers());
            this.props.dispatch(clearAddress());
        }
    };
    handleNext = () => {
        const {
            selectedBuildingType,
            notes,
            selectedMetalPanelSize,
            selectedGutterSize,
        } = this.state;

        if (this.state.activeStep === 2) {
            this.getFreeSketchOrders();
            const showHelperText =
                selectedBuildingType === BuildingTypes.None ||
                (this.state.sketchReportType === 'metalroof' &&
                    this.includeGutters &&
                    (selectedMetalPanelSize === MetalRoofOption.None.value ||
                        selectedMetalPanelSize === null)) ||
                (this.state.sketchReportType === 'gutters' &&
                    (selectedGutterSize === null ||
                        selectedGutterSize === null)) ||
                (this.state.sketchReportType === 'metalroof' &&
                    this.includeGutters &&
                    (selectedMetalPanelSize === MetalRoofOption.None.value ||
                        selectedMetalPanelSize === null ||
                        selectedGutterSize === null ||
                        selectedGutterSize === null)) ||
                (this.state.sketchReportType === 'fullsnap' &&
                    this.includeGutters &&
                    (selectedGutterSize === null ||
                        selectedGutterSize === null));

            if (showHelperText) {
                this.setState({
                    showProjectDetailsHelperText: true,
                });
                return;
            }

            if (selectedBuildingType === BuildingTypes.Other && notes === '') {
                return;
            }
            if (
                selectedBuildingType === BuildingTypes.PartialStructure &&
                notes === ''
            ) {
                return;
            }
        }

        if (
            this.getActiveStep() === 3 &&
            this.state.sketchReportType === OrderTypes.MetalRoof
        ) {
            if (
                selectedMetalPanelSize === MetalRoofOption.None.value ||
                selectedMetalPanelSize == null
            ) {
                this.setState({
                    showProjectDetailsHelperText: true,
                });
                return;
            }

            if (selectedGutterSize == null) {
                this.setState({
                    showProjectDetailsHelperText: true,
                });
            }
        }

        if (
            this.state.activeStep === 0 &&
            this.getExistingSketchOrdersFromState().length > 0
        ) {
            this.setState({ showExistingOrderWarning: true });
        } else {
            this.setState({
                showExistingOrderWarning: false,
                activeStep: this.state.activeStep + 1,
            });
        }
    };
    handleSubmit = async () => {
        let nonce: string = '';
        if (
            this.state.paymentType === PAYMENT_TYPE.USER &&
            this.state.getNonce
        ) {
            nonce = await this.state.getNonce();
        }

        await this.createSketchOrder(nonce);
    };
    handleBack = () => {
        this.setState({
            paymentType: PAYMENT_TYPE.None,
            paymentValid: false,
            getNonce: undefined,
            activeStep: this.state.activeStep - 1,
        });
    };
    handleCancel = () => {
        this.props.history.push('/orders/create');
    };
    handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ [event.target.name]: event.target.value } as any);
    };
    handleRushedChanged = (rushed: boolean) => {
        this.setState({
            rushed,
        });
    };
    handleOwnerChange = (ownerUserId: number) => {
        this.setState({ ownerUserId });
    };
    handleZoomChanged = (zoom: number) => {
        if (this.props.markers.length === 0) return;
        this.setState({ zoom });
    };
    handleCenterChanged = (center: any) => {
        if (this.props.markers.length === 0) return;
        this.setState({
            center: {
                lat: center.lat(),
                lng: center.lng(),
            },
        });
    };
    handleAddressVerificationFormValidate = (isFormValid: boolean) => {
        this.setState({ addressVerificationFormIsValid: isFormValid });
    };
    handleAddressChanged = (address: string) => {
        this.props.dispatch(geocodeAddress(address));
    };
    handleMarkerAdded = (marker: google.maps.Marker) => {
        this.props.dispatch(addMarker(marker));
    };
    handleMarkerUpdated = (marker: google.maps.Marker) => {
        this.props.dispatch(updateMarker(marker));
    };
    handleMarkerRemoved = (marker: google.maps.Marker) => {
        if ((marker as any).type === EXISTING_SKETCHOS_ORDER_MARKER_TYPE) {
            return;
        }

        this.props.dispatch(removeMarker(marker));
    };
    // eslint-disable-next-line no-unused-vars
    handleMarkerClick = (
        event: google.maps.MouseEvent,
        marker: google.maps.Marker
    ) => {
        if ((marker as any).type === EXISTING_SKETCHOS_ORDER_MARKER_TYPE) {
            this.setState({ showInfoWindowForMarkerId: (marker as any).id });
        }
    };
    handleInfoWindowClosed = () => {
        this.setState({ showInfoWindowForMarkerId: undefined });
    };
    handleSketchOptionsChange = (selectedBuildingType: BuildingTypes) => {
        this.setState({ selectedBuildingType: selectedBuildingType });
    };

    handleMetalOptionsChange = (selectedMetalPanelSize: number | undefined) => {
        this.setState({ selectedMetalPanelSize });
    };

    handleGutterOptionsChange = (selectedGutterSize: number | undefined) => {
        this.setState({ selectedGutterSize });
    };

    isFinalStep = (activeStep: number) =>
        activeStep === 4 && this.state.sketchReportType;

    isOrderFree() {
        return (
            this.props.markers.length <=
            this.props.freeSketchOrderBalance - this.props.openSketchOrders
        );
    }
    checkSketchTechAvailable = async () => {
        try {
            const schedule: any = await getTodaysSchedule();
            if (
                schedule &&
                schedule.currentServerTime &&
                schedule.startTime &&
                schedule.endTime
            ) {
                this.setState({
                    isSketchTechAvailable:
                        schedule.currentServerTime >= schedule.startTime &&
                        schedule.currentServerTime < schedule.endTime,
                });
            }
        } catch (error) {
            LoggerWrapper.captureException(error);
        }
    };

    isInvalidBuildingType(): boolean {
        return (
            this.state.selectedBuildingType === BuildingTypes.None ||
            (this.state.selectedBuildingType === BuildingTypes.Other &&
                !this.state.notes)
        );
    }
    isInvalidGutterSize(): boolean {
        return this.state.selectedGutterSize === undefined;
    }
    getNextDisabled(): boolean {
        if (this.state.activeStep === 0) {
            return (
                this.props.markers.length === 0 ||
                this.state.fetchingExistingOrders
            );
        } else if (this.state.activeStep === 1) {
            return !this.state.addressVerificationFormIsValid;
        } else if (this.state.activeStep === 2) {
            if (this.state.sketchReportType === 'fullsnap') {
                return this.isInvalidBuildingType();
            } else if (this.state.sketchReportType === 'metalroof') {
                return (
                    this.state.selectedMetalPanelSize === undefined ||
                    this.isInvalidBuildingType() ||
                    (this.includeGutters && this.isInvalidGutterSize())
                );
            } else if (this.state.sketchReportType === 'gutters') {
                return (
                    this.isInvalidBuildingType() || this.isInvalidGutterSize()
                );
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    {this.state.activeStep === 0 && (
                        <Grid container direction='column' wrap='nowrap'>
                            <Grid item>
                                <Grid container>
                                    <Grid item>
                                        <Typography
                                            variant='h6'
                                            className={classes.stepTitle}
                                        >
                                            {this.state.sketchReportType ===
                                            'gutters'
                                                ? 'New gutter measurement order'
                                                : 'New measurement order'}
                                        </Typography>
                                        <Typography
                                            variant='body1'
                                            className={classes.stepSubtitle}
                                        >
                                            Search for an address and move the
                                            pin to the correct building. For
                                            apartment complexes or condos, click
                                            to drop multiple pins on each
                                            structure. Each pin will create a
                                            separate order. Double click a pin
                                            to remove it.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        {/* <MapIconLegend /> */}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Location
                                    onAddressChanged={this.handleAddressChanged}
                                    markers={this.getMarkersToShow()}
                                    onMarkerAdded={this.handleMarkerAdded}
                                    onMarkerUpdated={this.handleMarkerUpdated}
                                    onMarkerRemoved={this.handleMarkerRemoved}
                                    onMarkerClick={this.handleMarkerClick}
                                    onMarkerInfoWindowClosed={
                                        this.handleInfoWindowClosed
                                    }
                                    center={this.state.center}
                                    zoom={this.state.zoom}
                                    onZoomChanged={this.handleZoomChanged}
                                    onCenterChanged={this.handleCenterChanged}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {this.state.activeStep === 1 && (
                        <Grid item xs container direction='column'>
                            <Grid item>
                                <Typography
                                    variant='h6'
                                    className={classes.stepTitle}
                                >
                                    Address verification
                                </Typography>
                                <Typography
                                    variant='body1'
                                    className={classes.stepSubtitle}
                                >
                                    Verify address details
                                </Typography>
                            </Grid>
                            <Grid item>
                                <AddressVerification
                                    address={this.state.address}
                                    city={this.state.city}
                                    region={this.state.region}
                                    postcode={this.state.postcode}
                                    country={this.state.country}
                                    onChange={this.handleOnChange}
                                    onFormValidate={
                                        this
                                            .handleAddressVerificationFormValidate
                                    }
                                />
                            </Grid>
                        </Grid>
                    )}
                    {this.state.activeStep === 2 && (
                        <Grid item xs container direction='column'>
                            <Grid item>
                                <Typography
                                    variant='h6'
                                    className={classes.stepTitle}
                                >
                                    Project details
                                </Typography>
                                <Typography
                                    variant='body1'
                                    className={classes.stepSubtitle}
                                >
                                    Project name, owner and notes
                                </Typography>
                            </Grid>
                            <Grid item>
                                <SketchOrderProjectDetails
                                    users={this.props.users}
                                    projectName={this.state.projectName}
                                    notes={this.state.notes}
                                    ownerUserId={this.state.ownerUserId}
                                    onChange={this.handleOnChange}
                                    onOwnerChange={this.handleOwnerChange}
                                    onSelectedBuildingTypeChange={
                                        this.handleSketchOptionsChange
                                    }
                                    selectedBuildingType={
                                        this.state.selectedBuildingType
                                    }
                                    onMetalSizeOptionsChange={
                                        this.handleMetalOptionsChange
                                    }
                                    selectedMetalPanelSize={
                                        this.state.selectedMetalPanelSize
                                    }
                                    onGutterOptionsChange={
                                        this.handleGutterOptionsChange
                                    }
                                    selectedGutterSize={
                                        this.state.selectedGutterSize
                                    }
                                    showRequiredHelperText={
                                        this.state.showProjectDetailsHelperText
                                    }
                                    sketchReportType={`${this.state.sketchReportType}`}
                                    includesGutters={this.includeGutters}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {this.state.activeStep === 3 && (
                        <OrderSummary
                            sketchReportType={this.state.sketchReportType}
                            numberOfOrdersBeingPlaced={
                                this.props.markers.length
                            }
                            freeSketchOrderBalance={
                                this.props.freeSketchOrderBalance
                            }
                            openSketchOrders={this.props.openSketchOrders}
                            orgId={this.props.organizationId}
                            rushed={this.state.rushed}
                            handleRushedChanged={this.handleRushedChanged}
                            rushOrdering={this.props.rushOrdering}
                            isBusinessHours={
                                this.state.isSketchTechAvailable ?? false
                            }
                            isRushAvailable={
                                this.state.isSketchTechAvailable &&
                                this.props.markers.length < 2 &&
                                this.props.rushOrdering
                            }
                            includeGutters={this.includeGutters}
                        />
                    )}
                    {this.state.activeStep === 4 && (
                        <PaymentStep
                            onPaymentChange={this.onPaymentChange}
                            isOrderFree={this.isOrderFree()}
                            isRushed={this.state.rushed}
                            numberOfOrdersBeingPlaced={
                                this.props.markers.length
                            }
                            freeSketchOrderBalance={
                                this.props.freeSketchOrderBalance
                            }
                            openSketchOrders={this.props.openSketchOrders}
                        />
                    )}
                    <Grid style={{ margin: '30px 0' }}>
                        <Stepper
                            activeStep={this.state.activeStep}
                            alternativeLabel
                            className={classes.stepper}
                        >
                            <Step
                                key='markers'
                                className={classes.stepContainer}
                            >
                                <StepLabel>
                                    <Typography className={classes.stepLabel}>
                                        Mark locations
                                    </Typography>
                                </StepLabel>
                            </Step>
                            <Step
                                key='address'
                                className={classes.stepContainer}
                            >
                                <StepLabel
                                    className={classes.stepLabelContainer}
                                >
                                    <Typography className={classes.stepLabel}>
                                        Address verification
                                    </Typography>
                                </StepLabel>
                            </Step>
                            <Step
                                key='project'
                                className={classes.stepContainer}
                            >
                                <StepLabel>
                                    <Typography className={classes.stepLabel}>
                                        Project details
                                    </Typography>
                                </StepLabel>
                            </Step>
                            <Step
                                key='summary'
                                className={classes.stepContainer}
                            >
                                <StepLabel>
                                    <Typography className={classes.stepLabel}>
                                        Order summary
                                    </Typography>
                                </StepLabel>
                            </Step>
                            <Step
                                key='payment'
                                className={classes.stepContainer}
                            >
                                <StepLabel>
                                    <Typography className={classes.stepLabel}>
                                        Payment
                                    </Typography>
                                </StepLabel>
                            </Step>
                        </Stepper>
                    </Grid>
                    <Grid container justifyContent='flex-end'>
                        <Grid item>
                            <Button onClick={this.handleCancel}>Cancel</Button>
                            <Button
                                disabled={this.state.activeStep === 0}
                                onClick={this.handleBack}
                                className={classes.backButton}
                            >
                                Back
                            </Button>
                            {!this.isFinalStep(this.state.activeStep) && (
                                <Button
                                    id='order-next-button'
                                    variant='contained'
                                    disabled={this.getNextDisabled()}
                                    color='primary'
                                    onClick={this.handleNext}
                                >
                                    Next
                                </Button>
                            )}
                            {this.isFinalStep(this.state.activeStep) && (
                                <Button
                                    id='order-submit-button'
                                    color='primary'
                                    variant='contained'
                                    disabled={
                                        !(
                                            this.state.paymentType &&
                                            this.state.paymentValid
                                        )
                                    }
                                    onClick={this.handleSubmit}
                                >
                                    {this.props.markers.length === 1
                                        ? 'Submit Order'
                                        : `Submit ${this.props.markers.length} Orders`}
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
                <Dialog
                    onClose={(_, reason) => reason !== 'backdropClick'}
                    open={this.state.showExistingOrderWarning}
                    disableEscapeKeyDown
                >
                    <DialogTitle id='sketchos-duplicate-warning'>
                        Warning
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            It appears that one of your orders is within 25
                            meters of an existing SketchOS you have already
                            ordered. If this is OK, please press the Proceed
                            button to continue. If not, please review for
                            possible duplicate order.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color='secondary'
                            onClick={this.onClickDialog(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            id='proximity-warning-proceed-button'
                            color='primary'
                            onClick={this.onClickDialog(true)}
                        >
                            Proceed
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    const {
        currentUser,
        organization,
        sketchOsGeocoding,
        organizationUsers,
        organizationFreeSketchOrders,
        SketchOSOfficeIdReducer,
        organizationFeatures,
    } = state;
    const {
        address: sketchOsGeocodingAddress,
        coordinates,
        markers,
    } = sketchOsGeocoding;
    const {
        addressLine1: address,
        city,
        state: region,
        postalCode: postcode,
        country,
    } = sketchOsGeocodingAddress;
    const { currentUserRoles } = state;
    const { data: users } = organizationUsers;
    const { organizationId, userId: currentUserId } = currentUser;
    const { freeSketchOrderBalance, openSketchOrders } =
        organizationFreeSketchOrders;
    const { sketchOSOfficeId } = SketchOSOfficeIdReducer;
    const { rushOrdering } = organizationFeatures;

    return {
        address,
        city,
        region,
        postcode,
        country,
        markers,
        coordinates,
        currentUserId,
        organizationId,
        organization,
        currentUser,
        currentUserRoles,
        freeSketchOrderBalance,
        openSketchOrders,
        users: orderBy(
            users,
            [(user) => user.userName.toLowerCase()],
            ['asc']
        ).filter((user) => user.active === true),
        sosDeliveryOfficeId: sketchOSOfficeId,
        rushOrdering,
    };
};

export default withRouter(
    connect(mapStateToProps)(withStyles(styles)(CreateSketchOrderPage))
);

import { Box, Grid } from '@material-ui/core';
import React from 'react';
import OrderSummaryHeader from '../OrderSummaryHeader';
import OrderType from './OrderType';
import { AllowedOrderTypes, OrderTypes } from 'lib/MeasurementOrders';
import gutterOption from 'lib/GutterOption';

type OrderReportTypesProps = {
    onMetalSizeOptionsChange?: (metalOptions: number | undefined) => void;
    selectedMetalPanelSize: number | undefined;
    onGutterOptionsChange?: (gutterOptions: number | undefined) => void;
    selectedGutterSize: number | undefined;
    onSketchReportTypeChange?: (allowedOrderTypes: AllowedOrderTypes) => void;
    sketchReportType: AllowedOrderTypes;
    includeGutters: boolean;
    handleIncludeGutters: (includeGutters: boolean) => void;
};

const OrderReportTypes = ({
    selectedMetalPanelSize,
    onMetalSizeOptionsChange,
    onGutterOptionsChange,
    selectedGutterSize,
    onSketchReportTypeChange,
    sketchReportType,
    includeGutters,
    handleIncludeGutters,
}: OrderReportTypesProps) => {
    const handleOrderTypeChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        //reset the gutters options
        if (onGutterOptionsChange)
            onGutterOptionsChange(gutterOption['None'].value);

        handleIncludeGutters(false);

        if (onSketchReportTypeChange)
            onSketchReportTypeChange(event.target.value);
    };

    return (
        <Grid container xs={12} direction='column'>
            <Grid item>
                <OrderSummaryHeader
                    header='Select Report Type'
                    isRequired={true}
                ></OrderSummaryHeader>
            </Grid>
            <Grid item>
                {Object.entries(OrderTypes).map((item) => {
                    return (
                        <Box sx={{ mt: '5px' }}>
                            <OrderType
                                onMetalSizeOptionsChange={
                                    onMetalSizeOptionsChange
                                }
                                selectedMetalPanelSize={selectedMetalPanelSize}
                                onGutterOptionsChange={onGutterOptionsChange}
                                selectedGutterSize={selectedGutterSize}
                                onChange={handleOrderTypeChange}
                                selectedOrderType={sketchReportType}
                                key={item[1]}
                                reportType={item[1]}
                                includeGutters={includeGutters}
                                handleIncludeGutters={handleIncludeGutters}
                            ></OrderType>
                            <Box paddingBottom='10px'></Box>
                        </Box>
                    );
                })}
            </Grid>
        </Grid>
    );
};

export default OrderReportTypes;

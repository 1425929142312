import * as React from 'react';
import ProjectDetails from './V2/ProjectDetails';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectById } from 'actions/ProjectActions';
import { RoofSnapState } from 'lib/Models/RoofSnapState';
import { Grid } from '@material-ui/core';
import { Box } from '@mui/material';

// TODO: Rename this to ProjectHome as part of the cleanup after Easier Estimates is complete
type ProjectParams = {
    projectId: string;
};
export const ProjectHomeV2: React.FC = () => {
    const { projectId } = useParams<ProjectParams>();
    const dispatch = useDispatch();
    const { currentProject } = useSelector(
        (store: RoofSnapState) => store.project
    );
    React.useEffect(() => {
        dispatch(getProjectById(projectId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId]);
    return (
        <Grid container justifyContent='center'>
            <Grid item xs={12} sm={10} lg={8} xl={6}>
                <Box p={2}>
                    <ProjectDetails project={currentProject}></ProjectDetails>
                </Box>
            </Grid>
        </Grid>
    );
};

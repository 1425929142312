import React, { useEffect, useState } from 'react';
import { FormControl, Input, InputLabel, MenuItem, Select } from '@material-ui/core';
import { connect } from 'react-redux';
import { getUsersOfficesForSOS } from './SketchOSOfficeByUserActions';
import { setSketchOSOfficeId } from './SketchOSOfficeIdAction';
import Office from '../../../../lib/Models/Office';

interface SketchOSOfficeDropdownProps {
    className?: string,
    selectedUserId?: number,
    dispatch: any,
    sosUserOffices: any,
    sketchOSOfficeId?: number,
}

const SketchOSOfficeDropdown = (props: SketchOSOfficeDropdownProps) => {
    const {
        className, selectedUserId, dispatch, sketchOSOfficeId, sosUserOffices
    } = props;
    const [offices, setOffices] = useState<Array<Office>>();

    useEffect(() => {
        if(sosUserOffices && selectedUserId && sosUserOffices[selectedUserId] && sosUserOffices[selectedUserId].length > 0)
        {
            // use cached user office
            setOffices(sosUserOffices[selectedUserId]);
        } else {
            const data = dispatch(getUsersOfficesForSOS(selectedUserId));
            data.then((r: any) => {
                setOffices(r.offices);
            });
        }
    }, [dispatch, selectedUserId, sosUserOffices]);

    useEffect(() => {
        if (offices && offices[0] && !offices.some(o => o.id === sketchOSOfficeId))
        {
            dispatch(setSketchOSOfficeId(offices[0].id));
        }
    }, [offices, sketchOSOfficeId, dispatch]);

    return (<FormControl
        fullWidth
        className={className}
    >
        <InputLabel shrink>Office</InputLabel>
        <Select
            value={sketchOSOfficeId}
            onChange={(e) => {
                const newOfficeId = e.target.value as number;
                dispatch(setSketchOSOfficeId(newOfficeId));
            }}
            input={
              <Input name="officeId" />
            }
        >
            {offices && offices.map((office: Office) => (<MenuItem key={office.id} value={office.id}>{office.officeName}</MenuItem>))}
        </Select>
    </FormControl>);
};

const mapStateToProps = (state: any) => {
    const { SketchOSOfficeByUserReducer, SketchOSOfficeIdReducer } = state;
    const { sosUserOffices } = SketchOSOfficeByUserReducer;
    const { sketchOSOfficeId } = SketchOSOfficeIdReducer;
    return {
        sosUserOffices,
        sketchOSOfficeId,
    };
};

export default connect(mapStateToProps)(SketchOSOfficeDropdown);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sortBy, filter } from 'lodash';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import ProjectListItem from '../ProjectList/ProjectListItem';
import ProjectShareModal from '../ProjectShareModal';
import CloneProjectModal from '../CloneProjectModal/CloneProjectModal';
import ProjectGiveModal from '../ProjectGiveModal';
import ProjectHomeCards from './ProjectHomeCards';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import UserRoles from '../../lib/UserRoles';
import { projectMenusVisible, projectRoutes } from '../../lib/ProjectMenu';
import { deleteProjectById } from '../../actions/ProjectActions';
import { getProjectSharesPerProject } from '../../actions/ProjectSharesActions';
import { getOfficeUsers } from '../../actions/Offices';
import PageToolbarActions, {
    ToolbarDeleteButton,
    ToolbarGiveProjectButton,
    ToolbarShareProjectButton,
    ToolbarCloneProjectButton,
} from '../NavigationComponents/PageToolbarActions';
import './ProjectHome.css';
import featureFlags from '../../lib/FeatureFlags.ts';
import OpenInExternalAppButton from '../OpenInExternalAppButton';
import hostConfig from '../../lib/hostConfig.ts';
import { setReduxMessage } from '../../actions/ReduxMessagesActions';

const style = {
    root: {
        padding: 24,
    },
};

class ProjectHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmDeleteProjectOpen: false,
            giveProjectModalIsOpen: false,
            shareProjectSelectionOpen: false,
            cloneProjectSelectionOpen: false,
            noUsersInOfficeDialogIsOpen: false,
        };
    }

    getSortedActiveOfficeUsers = (excludingProjectOwner) => {
        let filteredOfficeUsers = filter(
            this.props.usersInOffice,
            (user) => user.active === true
        );
        if (excludingProjectOwner) {
            filteredOfficeUsers = filter(
                filteredOfficeUsers,
                (user) =>
                    user.userName !== this.props.currentProject.projectOwner
            );
        }

        const sortedOfficeUsers = sortBy(filteredOfficeUsers, ['lastName']);

        return sortedOfficeUsers.map((user) => ({
            id: user.userId,
            displayString: `${user.firstName} ${user.lastName} (${user.userName})`,
        }));
    };

    cardClick = (route, isLocked) => {
        if (isLocked) {
            this.props.dispatch(
                setReduxMessage(
                    'Subscribe for access to estimates and exclusive order discounts.',
                    <Button
                        color='inherit'
                        onClick={() =>
                            this.props.history.push('/settings/billing')
                        }
                    >
                        Subscribe
                    </Button>
                )
            );
            return;
        }
        this.props.history.push(
            `/projects/${this.props.currentProject.id}/${route}`
        );
    };

    detailsClick = async () => {
        await this.props.history.push(
            `/projects/${this.props.currentProject.id}/${projectRoutes.Details}`
        );
    };

    deleteProject = () => {
        this.props
            .dispatch(deleteProjectById(this.props.match.params.id))
            .then(() => {
                this.props.history.push({
                    pathname: '/projects',
                    state: { message: this.props.message },
                });
            });
    };

    handleCloseGiveModal = (isCancelled) => {
        if (!isCancelled) {
            this.props.history.push({
                pathname: '/projects',
                state: { message: this.props.message },
            });
        }
        this.setState({ giveProjectModalIsOpen: false });
    };

    openDeleteProjectDialog = (e) => {
        e.preventDefault();
        this.setState({ confirmDeleteProjectOpen: true });
    };

    openShareProjectDialog = async (e) => {
        e.preventDefault();
        await this.props.dispatch(
            getProjectSharesPerProject(this.props.currentProject.id, 1, true)
        );
        await this.props.dispatch(
            getOfficeUsers(this.props.currentProject.officeId, 1, true)
        );

        const officeUsersMoreThanCurrentUser =
            this.props.usersInOffice.length > 1;

        this.setState({
            shareProjectSelectionOpen: officeUsersMoreThanCurrentUser,
            noUsersInOfficeDialogIsOpen: !officeUsersMoreThanCurrentUser,
        });
    };

    openCloneProjectDialog = async (e) => {
        e.preventDefault();
        await this.props.dispatch(
            getOfficeUsers(this.props.currentProject.officeId, 1, true)
        );

        this.setState({ cloneProjectSelectionOpen: true });
    };

    openGiveProjectDialog = async (e) => {
        e.preventDefault();
        await this.props.dispatch(
            getOfficeUsers(this.props.currentProject.officeId, 1, true)
        );

        const officeUsersMoreThanCurrentUser =
            this.props.usersInOffice.length > 1;

        this.setState({
            giveProjectModalIsOpen: officeUsersMoreThanCurrentUser,
            noUsersInOfficeDialogIsOpen: !officeUsersMoreThanCurrentUser,
        });
    };

    handleShareProjectClose = (e) => {
        e.preventDefault();
        this.setState({ shareProjectSelectionOpen: false });
    };

    handleCloneProjectClose = (e) => {
        e.preventDefault();
        this.setState({ cloneProjectSelectionOpen: false });
    };

    handleDeleteProjectClose = (e) => {
        e.preventDefault();
        this.setState({ confirmDeleteProjectOpen: false });
    };

    handleNoUsersInOfficeDialogIsOpenButtonOkClick = () => {
        this.setState({ noUsersInOfficeDialogIsOpen: false });
    };

    renderCard = (elem) => {
        const isLocked =
            elem.isSubscriberOnly &&
            this.props.organization &&
            this.props.organization.subscriptionExpired;

        return (
            <ProjectHomeCards
                id={elem.id}
                key={elem.Title}
                title={elem.Title}
                description={elem.Description}
                innerDivStyle={{ backgroundColor: elem.Color }}
                callBack={() => this.cardClick(elem.Route, isLocked)}
                isLocked={isLocked}
            >
                {elem.Icon}
            </ProjectHomeCards>
        );
    };

    render() {
        const { currentProject } = this.props;
        const currentUserRoles = [];
        if (this.props.estimatesDisabled) {
            currentUserRoles.push(UserRoles.EstimatesDisabled);
        }
        const visibleMenus = projectMenusVisible.filter(
            (item) =>
                !(
                    item.hideForRoles &&
                    item.hideForRoles.some((r) => currentUserRoles.includes(r))
                )
        );
        const officeUsersExcludingProjectOwner =
            this.getSortedActiveOfficeUsers(true);
        const officeUsersIncludingProjectOwner =
            this.getSortedActiveOfficeUsers(false);
        return (
            <div className='projectHomeContainer' style={style.root}>
                <PageToolbarActions triggers={[currentProject.externalId]}>
                    <ToolbarDeleteButton
                        onClick={this.openDeleteProjectDialog}
                        tooltip='Delete Project'
                    />
                    <ToolbarGiveProjectButton
                        onClick={this.openGiveProjectDialog}
                        tooltip='Give Project'
                    />
                    <ToolbarShareProjectButton
                        onClick={this.openShareProjectDialog}
                        tooltip='Share Project'
                    />
                    {featureFlags.cloneProject && (
                        <ToolbarCloneProjectButton
                            onClick={this.openCloneProjectDialog}
                            tooltip='Clone Project'
                        />
                    )}
                    <OpenInExternalAppButton
                        baseUrl={hostConfig.externalAppUrl}
                        externalId={currentProject.externalId}
                        appName={hostConfig.externalAppName}
                        variant='contained'
                        color='primary'
                    />
                </PageToolbarActions>
                <Card className='details'>
                    <ProjectListItem
                        key={currentProject.id}
                        project={currentProject}
                        value={currentProject}
                        onProjectListItemClick={this.detailsClick}
                    />
                </Card>
                <div className='cards'>{visibleMenus.map(this.renderCard)}</div>
                <ConfirmationModal
                    title='Delete Project'
                    description='Are you sure you want to remove this project?'
                    handleConfirm={this.deleteProject}
                    confirmText='Delete'
                    isOpen={this.state.confirmDeleteProjectOpen}
                    handleClose={this.handleDeleteProjectClose}
                />
                <Dialog open={this.state.noUsersInOfficeDialogIsOpen}>
                    <DialogTitle>Unable to distribute project</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            There are no other users in current project&#39;s
                            office.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={
                                this
                                    .handleNoUsersInOfficeDialogIsOpenButtonOkClick
                            }
                        >
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
                <ProjectGiveModal
                    dispatch={this.props.dispatch}
                    giveProjectModalIsOpen={this.state.giveProjectModalIsOpen}
                    handleCloseGiveModal={this.handleCloseGiveModal}
                    officeUsers={officeUsersExcludingProjectOwner}
                    projectId={currentProject.id}
                />
                <ProjectShareModal
                    dispatch={this.props.dispatch}
                    officeUsers={officeUsersExcludingProjectOwner}
                    shareProjectSelectionOpen={
                        this.state.shareProjectSelectionOpen
                    }
                    projectSharesForThisProject={
                        this.props.projectSharesForThisProject
                    }
                    projectId={currentProject.id}
                    handleShareProjectClose={this.handleShareProjectClose}
                />
                <CloneProjectModal
                    dispatch={this.props.dispatch}
                    officeUsers={officeUsersIncludingProjectOwner}
                    cloneProjectSelectionIsOpen={
                        this.state.cloneProjectSelectionOpen
                    }
                    projectId={currentProject.id}
                    projectName={currentProject.projectName}
                    handleCloneProjectClose={this.handleCloneProjectClose}
                />
            </div>
        );
    }
}
/* eslint-disable react/forbid-prop-types */
ProjectHome.propTypes = {
    history: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    currentProject: PropTypes.object,
    message: PropTypes.string,
    usersInOffice: PropTypes.arrayOf(PropTypes.object),
    projectSharesForThisProject: PropTypes.arrayOf(PropTypes.object),
};

ProjectHome.defaultProps = {
    currentProject: {},
    message: '',
    usersInOffice: [],
    projectSharesForThisProject: [],
};

const mapStateToProps = (state) => {
    const {
        projectHome,
        officeUsers,
        projectSharesPerProject,
        organization,
        currentUserRoles,
    } = state;
    const { currentProject, message } = projectHome;
    const { estimatesDisabled } = currentUserRoles;
    const { data: usersInOffice } = officeUsers;
    const { data: projectSharesForThisProject } = projectSharesPerProject;

    return {
        currentProject,
        message,
        usersInOffice,
        projectSharesForThisProject,
        organization,
        estimatesDisabled,
    };
};

export default connect(mapStateToProps)(ProjectHome);

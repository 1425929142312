import React from 'react';
import {
    Box,
    Card,
    CardMedia,
    Divider,
    Grid,
    IconButton,
    Link,
    Stack,
    styled,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Project from 'lib/Models/Project';
import ProjectDetailRow from './ProjectDetailRow';
import { ReactComponent as PhoneIcon } from 'assets/PhoneIcon.svg';
import { ReactComponent as EmailIcon } from 'assets/EmailIcon.svg';
import { ReactComponent as EditIcon } from 'assets/EditIcon.svg';
import { ReactComponent as NotesIcon } from 'assets/NotesIcon.svg';
import { ReactComponent as PersonIcon } from 'assets/PersonIcon.svg';

const SubtitleTypography = styled(Typography)(() => ({
    color: '#555',
    fontSize: 11,
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
}));
const AddressTypography = styled(Typography)(() => ({
    fontSize: 22,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '28px',
}));
const DataFieldTextTypography = styled(Typography)(() => ({
    color: '#222',
    textOverflow: 'ellipsis',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.5px',
}));
const LinkContainer = styled(Link)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'ellipsis',
    width: '50%',
    textDecoration: 'none',
}));
const LinkTextTypography = styled(Typography)(() => ({
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
    color: '#056CF2',
    ml: 1,
}));
interface ProjectInfoProps {
    project: Project;
}
const ProjectInfo: React.FC<ProjectInfoProps> = ({ project }) => {
    const theme = useTheme();
    const isMediumScreen: boolean = useMediaQuery(
        theme.breakpoints.between('md', 'lg')
    );
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: { xs: 'auto', sm: '353px' },
            }}
            p={3}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <AddressTypography>
                    {project.customerAddress}
                    {project.customerCity && project.customerState && (
                        <>
                            <br />
                            {project.customerCity}, {project.customerState}
                        </>
                    )}
                    {project.customerZip && (
                        <>
                            <br />
                            {project.customerZip}
                        </>
                    )}
                </AddressTypography>
                <Divider sx={{ mt: 1, mb: 1 }} />
                <Stack sx={{ pt: 1, pb: 1 }} spacing={2}>
                    <Stack direction='row' spacing={2} alignItems={'center'}>
                        <PersonIcon width={24} height={24} />
                        <Stack>
                            <SubtitleTypography>Homeowner</SubtitleTypography>
                            <DataFieldTextTypography>
                                {project.customerName}
                            </DataFieldTextTypography>
                        </Stack>
                    </Stack>
                    <Stack direction='row' spacing={2} alignItems={'center'}>
                        <EmailIcon width={24} height={24} />
                        <Stack>
                            <SubtitleTypography>Email</SubtitleTypography>
                            <DataFieldTextTypography>
                                {project.customerEmail}
                            </DataFieldTextTypography>
                        </Stack>
                    </Stack>
                    <Stack direction='row' spacing={2} alignItems={'center'}>
                        <PhoneIcon width={24} height={24} />
                        <Stack>
                            <SubtitleTypography>Phone</SubtitleTypography>
                            <DataFieldTextTypography>
                                {project.customerPhone}
                            </DataFieldTextTypography>
                        </Stack>
                    </Stack>
                </Stack>
            </Box>
            <Box>
                <Divider sx={{ mb: 1 }} />
                <Stack
                    direction='row'
                    spacing={isMediumScreen ? 1 : 4}
                    justifyContent={'space-between'}
                >
                    <LinkContainer>
                        <IconButton>
                            <EditIcon color='blue' />
                        </IconButton>
                        <LinkTextTypography>Edit Details</LinkTextTypography>
                    </LinkContainer>
                    <Divider orientation='vertical' flexItem />
                    <LinkContainer>
                        <IconButton>
                            <NotesIcon color='blue' />
                        </IconButton>
                        <LinkTextTypography>Add Notes</LinkTextTypography>
                    </LinkContainer>
                </Stack>
            </Box>
        </Box>
    );
};
interface ProjectImageProps {
    imageUrl: string;
}

const ProjectImage: React.FC<ProjectImageProps> = ({ imageUrl }) => {
    return (
        <CardMedia
            component='img'
            sx={{
                width: '100%',
                height: { xs: '200px', sm: '401px' },
                objectFit: 'cover',
                objectPosition: 'center',
            }}
            image={imageUrl ? imageUrl : '/assets/projectPlaceholder.png'}
            alt='Roof Snap Image'
        />
    );
};

interface ProjectDetailsProps {
    project: Project;
}

const ProjectDetails: React.FC<ProjectDetailsProps> = ({ project }) => {
    return (
        <Card>
            <Grid
                container
                sx={{ flexDirection: { xs: 'column', md: 'row-reverse' } }}
            >
                <Grid item xs={12} md={8}>
                    <ProjectImage imageUrl={project.roofSnapImageUrl} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <ProjectInfo project={project} />
                </Grid>
            </Grid>
            <ProjectDetailRow />
        </Card>
    );
};

export default ProjectDetails;

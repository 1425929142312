import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';

const style = theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginLeft: 0,
    },
});

class AddressVerification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isValid: {
                address: !!props.address,
                city: !!props.city,
                region: !!props.region,
                postcode: !!props.postcode,
                country: !!props.country,
            },
        };
        this.props.onFormValidate(this.isFormValid());
    }
    componentDidUpdate(prevProps) {
        if (this.didFormPropsChange(prevProps)) {
            this.setFormState();
            this.props.onFormValidate(this.isFormValid());
        }
    }
    setFormState = () => {
        this.setState({
            isValid: {
                address: !!this.props.address,
                city: !!this.props.city,
                region: !!this.props.region,
                postcode: !!this.props.postcode,
                country: !!this.props.country,
            },
        });
    };
    didFormPropsChange = prevProps => (
        prevProps.address !== this.props.address
        || prevProps.city !== this.props.city
        || prevProps.region !== this.props.region
        || prevProps.postcode !== this.props.postcode
        || prevProps.country !== this.props.country
    );
    handleFieldChange = (event) => {
        this.setState({
            isValid: Object.assign(this.state.isValid, { [event.target.name]: !!event.target.value }),
        });
        this.props.onChange(event);
    };
    isFormValid = () => {
        const {
            address,
            city,
            region,
            postcode,
            country,
        } = this.state.isValid;
        return address
            && city
            && region
            && postcode
            && country;
    };
    render() {
        const { isValid } = this.state;
        return (
            <div>
                <FormControl
                    className={this.props.classes.formControl}
                    fullWidth
                    error={!isValid.address}
                >
                    <InputLabel shrink>Address</InputLabel>
                    <Input
                        id="address"
                        type="text"
                        value={this.props.address}
                        onChange={this.handleFieldChange}
                        name="address"
                        inputProps={{
                            maxLength: 100,
                        }}
                    />
                    {!isValid.address &&
                    <FormHelperText id="address-warning">Address is required</FormHelperText>
                    }
                </FormControl>
                <FormControl
                    className={this.props.classes.formControl}
                    fullWidth
                    error={!isValid.city}
                >
                    <InputLabel shrink>City</InputLabel>
                    <Input
                        id="city"
                        type="text"
                        value={this.props.city}
                        onChange={this.handleFieldChange}
                        name="city"
                        inputProps={{
                            maxLength: 100,
                        }}
                    />
                    {!isValid.city &&
                    <FormHelperText id="city-warning">City is required</FormHelperText>
                    }
                </FormControl>
                <FormControl
                    className={this.props.classes.formControl}
                    fullWidth
                    error={!isValid.region}
                >
                    <InputLabel shrink>State/Region</InputLabel>
                    <Input
                        id="region"
                        type="text"
                        value={this.props.region}
                        onChange={this.handleFieldChange}
                        name="region"
                        inputProps={{
                            maxLength: 100,
                        }}
                    />
                    {!isValid.region &&
                    <FormHelperText id="region-warning">State/region is required</FormHelperText>
                    }
                </FormControl>
                <FormControl
                    className={this.props.classes.formControl}
                    fullWidth
                    error={!isValid.postcode}
                >
                    <InputLabel shrink>Postal code</InputLabel>
                    <Input
                        id="postcode"
                        type="text"
                        value={this.props.postcode}
                        onChange={this.handleFieldChange}
                        name="postcode"
                        inputProps={{
                            maxLength: 50,
                        }}
                    />
                    {!isValid.postcode &&
                    <FormHelperText id="postcode-warning">Postal code is required</FormHelperText>
                    }
                </FormControl>
                <FormControl
                    className={this.props.classes.formControl}
                    fullWidth
                    error={!isValid.country}
                >
                    <InputLabel shrink>Country</InputLabel>
                    <Input
                        id="country"
                        type="text"
                        value={this.props.country}
                        onChange={this.handleFieldChange}
                        name="country"
                        inputProps={{
                            maxLength: 100,
                        }}
                    />
                    {!isValid.country &&
                    <FormHelperText id="country-warning">Country is required</FormHelperText>
                    }
                </FormControl>
            </div>
        );
    }
}

AddressVerification.propTypes = {
    address: PropTypes.string,
    city: PropTypes.string,
    region: PropTypes.string,
    postcode: PropTypes.string,
    country: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onFormValidate: PropTypes.func.isRequired,
    classes: PropTypes.shape({
        formControl: PropTypes.string.isRequired,
    }).isRequired,
};

AddressVerification.defaultProps = {
    address: '',
    city: '',
    region: '',
    postcode: '',
    country: '',
};

export default withStyles(style)(AddressVerification);

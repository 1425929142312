import React, { Component, PropsWithChildren } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    Theme,
    WithStyles,
    createStyles,
    useTheme,
    withStyles,
} from '@material-ui/core/styles';
import { chain, orderBy } from 'lodash';

import {
    CREATE_BULK_SKETCH_ORDER_SUCCESS,
    createSketchOrders,
} from '../../../../actions/SketchOrderActions';
import {
    addMarker,
    clearAddress,
    clearMarkers,
    geocodeAddress,
    removeMarker,
    updateMarker,
} from '../../../../actions/SketchOsGeocodingActions';
import {
    getOrganizationOpenSketchOrders,
    getOrganizationsFreeSketchOrdersBalance,
} from '../../../../actions/OrganizationFreeSketchOrdersActions';
import PaymentStep from './PaymentStep';
import Location from './Location';
import LoggerWrapper from '../../../../lib/Logger';
import PAYMENT_TYPE, { PaymentType } from '../../../../lib/PaymentType';
import BuildingTypes from '../../../../lib/BuildingTypes';

import SketchOrderProjectDetails from './SketchOrderProjectDetails';

import { getOrganization } from '../../../../actions/Organization';
import { getSketchOSOrdersByOrganization } from '../../../../actions/SketchOSActions';
import { setReduxMessage } from '../../../../actions/ReduxMessagesActions';
import { getTodaysSchedule } from '../../../../lib/util/sketchTechAvailibilityHelper';
import {
    AllowedOrderTypes,
    OrderTypes,
} from '../../../../lib/MeasurementOrders';
import { GeoPoint } from 'lib/Models/GeoPoint';
import SketchOrder from 'lib/Models/SketchOrder';
import User from 'lib/Models/User';
import Organization from 'lib/Models/Organization';
import BuildingTypeSelector from './BuildingTypeSelector/BuildingTypeSelector';
import Notes from './Notes';
import BuildingTypeCategories from 'lib/BuildingTypeCategories';
import OrderSummary from './OrderSummary';
import { useMediaQuery } from '@material-ui/core';
import sanitizeHtml from 'sanitize-html';
import queryString from 'query-string';
import OrderReportTypes from './OrderType/OrderReportTypes';
import { getOrganizationUsers } from 'actions/Users';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(1),
            },
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(3),
            },
        },
        paper: {
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(2),
            },
            [theme.breakpoints.up('md')]: {
                padding: '2.5rem 3.75rem',
            },
            maxWidth: '52.6rem',
        },
        backButton: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        stepTitle: {
            marginTop: theme.spacing(3),
        },
        stepSubtitle: {
            marginBottom: theme.spacing(3),
            color: 'rgba(0,0,0,0.54)',
        },
        stepBody: {
            marginTop: theme.spacing(3),
            color: 'rgba(0,0,0,0.54)',
        },
        stepper: {
            '@media only screen and (max-width: 600px)': {
                padding: '24px 0px',
            },
        },
        stepContainer: {
            '@media only screen and (max-width: 600px)': {
                padding: '0px 4px',
                minWidth: '40px',
            },
            '@media only screen and (max-width: 350px)': {
                padding: '0px 2px',
            },
        },
        stepLabelContainer: {
            '@media only screen and (max-width: 600px)': {
                padding: '0px 4px',
                minWidth: '40px',
            },
        },
        stepLabel: {
            '@media only screen and (max-width: 600px)': {
                fontSize: '10px',
            },
            '@media only screen and (max-width: 350px)': {
                fontSize: '8px',
            },
        },
        orderSection: {
            paddingTop: '5px',
        },
    });

const stateDefaults = {
    center: {
        lat: 38,
        lng: -97,
    },
    zoom: 3,
};

const EXISTING_SKETCHOS_ORDER_MARKER_TYPE = Symbol(
    'EXISTING_SKETCHOS_ORDER_MARKER_TYPE'
);

const sleep = (ms: number) =>
    new Promise((resolve) => {
        setTimeout(resolve, ms);
    });

type CreateSketchOrderPageState = {
    address: string;
    city: string;
    region: string;
    postcode: string;
    country: string;
    projectName: string;
    ownerUserId: number;
    notes?: string;
    center: GeoPoint;
    zoom: number;
    paymentType: PaymentType | null;
    paymentValid: boolean;
    sketchReportType: AllowedOrderTypes;
    selectedBuildingType: BuildingTypes;
    selectedMetalPanelSize: number | undefined;
    includeGutters: boolean;
    selectedGutterSize: number | undefined;
    existingSketchOrders: any;
    showExistingOrderWarning: boolean;
    showInfoWindowForMarkerId?: number;
    showProjectDetailsHelperText: boolean;
    fetchingExistingOrders: boolean;
    rushed: boolean;
    isSketchTechAvailable: boolean;
    getNonce?: () => Promise<string>;
    activeStep: number;
    notesRequired: boolean;
    buildingTypeCategory: BuildingTypeCategories;
};

type CreateSketchOrderPageQueryParams = {
    address: string;
    city: string;
    region: string;
    postcode: string;
    country: string;
    notes?: string;
    sketchReportType: AllowedOrderTypes;
    selectedBuildingType: BuildingTypes;
    buildingTypeCategory: BuildingTypeCategories;
    selectedMetalPanelSize: number | undefined;
    selectedGutterSize: number | undefined;
    rushed: boolean;
};

type BuildingInfo = {
    buildingType: BuildingTypes;
    buildingTypeCategory: BuildingTypeCategories;
};

const buildingTypesMap: { [key: string]: BuildingInfo } = {
    singlefamily: {
        buildingTypeCategory: 'Single Family',
        buildingType: BuildingTypes.MainBuildingOnly,
    },
    includeoutbuilding: {
        buildingTypeCategory: 'Single Family',
        buildingType: BuildingTypes.MainBuildingWithGarage,
    },
    commercial: {
        buildingTypeCategory: 'Commercial',
        buildingType: BuildingTypes.WholeStructure,
    },
    partial: {
        buildingTypeCategory: 'Commercial',
        buildingType: BuildingTypes.PartialStructure,
    },
};

const OrderSection = (props: PropsWithChildren<{ className: string }>) => {
    // TODO: This component was introduced to deal with the fact that useTheme
    // and useMediaQuery are not available in react class components.
    // Ideally the parent component should be converted to a functions component.
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
        defaultMatches: true,
    });

    return (
        <Grid
            className={props.className}
            container
            item
            spacing={isMobile ? 0 : 6}
        >
            {props.children}
        </Grid>
    );
};

type CreateSketchOrderPageProps = {
    address: string;
    city: string;
    region: string;
    postcode: string;
    country: string;
    currentUserId: number;
    defaultSelectedSketchOptions: string[];
    organizationId: number;
    markers: any[];
    selectedMetalOptions: string[];
    selectedGutterOptions: string[];
    sosDeliveryOfficeId: string[];
    freeSketchOrderBalance: number;
    openSketchOrders: number;
    rushOrdering: boolean;
    history: any;
    match: any;
    location: any;
    dispatch: (arg: any) => any | void;
    users: User[];
    organization: Organization;
} & WithStyles<typeof styles, true>;

class CreateSketchOrderPage extends Component<
    CreateSketchOrderPageProps,
    CreateSketchOrderPageState
> {
    constructor(props: CreateSketchOrderPageProps) {
        super(props);

        const initState = this.populateStateFromPropsAndQueryParams();
        this.state = {
            ...initState,
            projectName: '',
            ownerUserId: props.currentUserId,
            center: stateDefaults.center,
            zoom: stateDefaults.zoom,
            paymentType: 'None',
            paymentValid: false,
            existingSketchOrders: {},
            showExistingOrderWarning: false,
            showInfoWindowForMarkerId: undefined,
            showProjectDetailsHelperText: false,
            fetchingExistingOrders: false,
            activeStep: this.getActiveStep(),
            notesRequired: false,
            isSketchTechAvailable: false,
            includeGutters: false,
        };
    }

    private populateStateFromPropsAndQueryParams(): CreateSketchOrderPageQueryParams {
        const queryStringParameters: any = queryString.parse(
            this.props.location.search
        );
        const sketchReportType: AllowedOrderTypes = this.getReportType(
            queryStringParameters.sketchReportType
        );
        const address: string = queryStringParameters.address
            ? sanitizeHtml(queryStringParameters.address)
            : '';
        const city: string = queryStringParameters.city
            ? sanitizeHtml(queryStringParameters.city)
            : '';
        const region: string = queryStringParameters.region
            ? sanitizeHtml(queryStringParameters.region)
            : '';
        const postcode: string = queryStringParameters.postcode
            ? sanitizeHtml(queryStringParameters.postcode)
            : '';
        const country: string = queryStringParameters.country
            ? sanitizeHtml(queryStringParameters.country)
            : '';
        const buildInfo: BuildingInfo = this.getBuildingInfoFromQuery(
            queryStringParameters.buildingType
        );
        const selectedGutterSize: number | undefined =
            this.getGutterSizeFromQuery(
                sketchReportType,
                queryStringParameters.selectedGutterSize
            );

        const selectedMetalPanelSize: number | undefined =
            this.getMetalPanelSizeFromQuery(
                sketchReportType,
                queryStringParameters.selectedMetalPanelSize
            );
        const rushed: boolean = this.getRushedFromQuery(
            sketchReportType,
            queryStringParameters.rushed
        );

        const notes: string = queryStringParameters.notes
            ? sanitizeHtml(queryStringParameters.notes)
            : '';
        return {
            address,
            city,
            region,
            postcode,
            country,
            notes,
            sketchReportType,
            selectedBuildingType: buildInfo.buildingType,
            buildingTypeCategory: buildInfo.buildingTypeCategory,
            selectedMetalPanelSize,
            selectedGutterSize,
            rushed,
        };
    }

    private getReportType(queryReportType: string): AllowedOrderTypes {
        if (this.props.match.params.sketchReportType) {
            return this.props.match.params.sketchReportType;
        }
        if (queryReportType) {
            let sanitized = sanitizeHtml(queryReportType);
            return sanitized in OrderTypes ? sanitized : OrderTypes.FullSnap;
        }
        return OrderTypes.FullSnap;
    }

    private getGutterSizeFromQuery(
        reportType: AllowedOrderTypes,
        gutterSize: string
    ): number | undefined {
        if (reportType !== OrderTypes.Gutters) {
            return undefined;
        }
        return gutterSize ? +sanitizeHtml(gutterSize) : undefined;
    }

    private getMetalPanelSizeFromQuery(
        reportType: AllowedOrderTypes,
        metalPanelSize: string
    ): number | undefined {
        if (reportType !== OrderTypes.MetalRoof) {
            return undefined;
        }
        return metalPanelSize ? +sanitizeHtml(metalPanelSize) : undefined;
    }

    private getRushedFromQuery(
        reportType: AllowedOrderTypes,
        rushed: string
    ): boolean {
        if (reportType === OrderTypes.MetalRoof || !rushed) {
            return false;
        }
        const sanitized = sanitizeHtml(rushed).toLocaleLowerCase();
        return sanitized === 'true';
    }

    private getBuildingInfoFromQuery(buildingType: string): BuildingInfo {
        let cleanedBuildingType: string = '';
        if (buildingType) {
            cleanedBuildingType = sanitizeHtml(buildingType).toLowerCase();
        }
        if (buildingTypesMap[cleanedBuildingType]) {
            return buildingTypesMap[cleanedBuildingType];
        } else {
            return {
                buildingTypeCategory: 'Single Family',
                buildingType: BuildingTypes.None,
            };
        }
    }

    async componentDidMount() {
        this.props.dispatch(
            getOrganizationUsers(this.props.organizationId, 1, true)
        );
        // check if we are NOT starting on step 0, and redirect browser to start.  this shouldn't happen
        // but if someone book marked or hit back potentially people could try to go to this.
        if (this.getActiveStep() !== 0) {
            this.props.history.push(
                `/orders/v2/create/${this.props.match.params.sketchReportType}`
            );
            return;
        }
        if (this.state.address) {
            this.props.dispatch(geocodeAddress(`${this.state.address}`));
        }
        this.props.dispatch(getOrganization());
        this.getFreeSketchOrders();
        await this.checkSketchTechAvailable();
    }

    async componentDidUpdate(prevProps: CreateSketchOrderPageProps) {
        if (
            prevProps.markers !== this.props.markers &&
            this.props.markers.length > 0
        ) {
            this.centerAndZoomMarker(this.props.markers[0]);
        }
        this.updateSketchOrderAddress(prevProps);
        if (
            this.props.markers !== prevProps.markers &&
            this.props.markers.length !== 0
        ) {
            // noinspection JSIgnoredPromiseFromCall
            this.getCloseSketchOrders();
        }
    }
    onPaymentChange = (
        paymentType: PaymentType | null,
        paymentValid: boolean,
        getNonce: () => Promise<string>
    ) => {
        this.setState({
            paymentType,
            paymentValid,
            getNonce,
        });
    };
    onClickDialog = (proceed: boolean) => () => {
        this.setState({ showExistingOrderWarning: false });
        if (proceed) {
            this.setState({ activeStep: this.state.activeStep + 1 });
        }
    };
    getFreeSketchOrders() {
        this.props.dispatch(
            getOrganizationsFreeSketchOrdersBalance(this.props.organizationId)
        );
        this.props.dispatch(
            getOrganizationOpenSketchOrders(this.props.organizationId)
        );
    }
    getCloseSketchOrders = async () => {
        const statePromises = this.props.markers.map((m) => {
            const stateKey = `${m.coordinates.lat}_${m.coordinates.lng}`;
            // see if we already have results
            if (this.state.existingSketchOrders[stateKey]) {
                return Promise.resolve({
                    orders: this.state.existingSketchOrders[stateKey],
                    key: stateKey,
                });
            }
            return (async () => {
                const geo = m.coordinates;
                this.setState({ fetchingExistingOrders: true });
                const sketchOsResp = await this.props.dispatch(
                    getSketchOSOrdersByOrganization(
                        this.props.organizationId,
                        1,
                        geo.lat,
                        geo.lng,
                        25
                    )
                );
                this.setState({ fetchingExistingOrders: false });
                let orders = [];
                if (
                    sketchOsResp &&
                    sketchOsResp.response &&
                    sketchOsResp.response.resourceList
                ) {
                    orders = sketchOsResp.response.resourceList;
                }

                return {
                    key: stateKey,
                    orders,
                };
            })();
        });

        const existingOrderArrays = await Promise.all(statePromises);
        const newState = existingOrderArrays.reduce(
            (acc: { [key: string]: any[] }, currentVal) => {
                acc[currentVal.key] = currentVal.orders;
                return acc;
            },
            {}
        );

        this.setState({ existingSketchOrders: newState });
    };
    getExistingSketchOrdersFromState = () =>
        chain(Object.keys(this.state.existingSketchOrders))
            .map((key) => this.state.existingSketchOrders[key])
            .flatten()
            .uniqBy((o) => o.id)
            .value();

    getMarkersToShow = () => {
        const orders = this.getExistingSketchOrdersFromState();

        const existingOrderMarkers = orders.map((so) => ({
            coordinates: {
                lat: so.location.coordinates[1],
                lng: so.location.coordinates[0],
            },
            draggable: false,
            icon: {
                url: '/assets/sketch-order-map-icon_4.png',
                labelOrigin: { x: 11, y: 29 },
            },
            label: { text: so.address, color: 'white' },
            title: `${so.address}`,
            type: EXISTING_SKETCHOS_ORDER_MARKER_TYPE,
            id: so.id,
            infoWindowContent: this.getInfoWindowForMarker(so),
        }));

        return this.props.markers.concat(existingOrderMarkers);
    };
    getInfoWindowForMarker = (sketchOrder: SketchOrder) => {
        if (sketchOrder.id !== this.state.showInfoWindowForMarkerId) {
            return undefined;
        }

        return (
            <div style={{ margin: '5px' }}>
                <div>
                    <b>Address</b>: {sketchOrder.address}, {sketchOrder.city},{' '}
                    {sketchOrder.region}
                </div>
                <div>
                    <b>Requester</b>: {sketchOrder.requester?.userName}
                </div>
                <div>
                    <b>Status</b>: {sketchOrder.sketchOrderStatus}
                </div>
                <div>
                    <b>Type</b>: {sketchOrder.sketchReportType}
                </div>
                <div>
                    <a
                        href={`/orders/${sketchOrder.id}`}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        Click here to open
                    </a>
                </div>
            </div>
        );
    };
    getPaymentType = () => {
        switch (this.state.paymentType) {
            case PAYMENT_TYPE.USER:
                return 2;
            case PAYMENT_TYPE.ORGANIZATION:
                return 1;
            case PAYMENT_TYPE.NONE:
                return 0;
            default:
                throw Error('Unable to figure out a paymentType');
        }
    };
    getActiveStep = () => {
        if (!this.props.match.params.activeStep) {
            return 0;
        }
        return Number.parseInt(this.props.match.params.activeStep, 10);
    };
    centerAndZoomMarker = (marker: google.maps.Marker) => {
        this.setState({
            center: (marker as any).coordinates,
            zoom: 19,
        });
    };
    updateSketchOrderAddress = (prevProps: CreateSketchOrderPageProps) => {
        if (prevProps.address !== this.props.address) {
            this.setState({ address: this.props.address });
        }
        if (prevProps.city !== this.props.city) {
            this.setState({ city: this.props.city });
        }
        if (prevProps.region !== this.props.region) {
            this.setState({ region: this.props.region });
        }
        if (prevProps.postcode !== this.props.postcode) {
            this.setState({ postcode: this.props.postcode });
        }
        if (prevProps.country !== this.props.country) {
            this.setState({ country: this.props.country });
        }
    };

    createSketchOrder = async (nonce: string) => {
        const sketchOrders: any[] = [];
        this.props.markers.forEach((marker, index) => {
            const multiProjectName =
                this.state.projectName === ''
                    ? index + 1
                    : `${this.state.projectName} - ${index + 1}`;
            const projectName =
                this.props.markers.length > 1
                    ? multiProjectName
                    : this.state.projectName;

            const baseOrder = {
                organizationId: this.props.organizationId,
                address: this.state.address,
                city: this.state.city,
                region: this.state.region,
                postcode: this.state.postcode,
                country: this.state.country,
                projectName,
                ownerUserId: this.state.ownerUserId,
                notes: this.state.notes,
                location: {
                    coordinates: [
                        marker.coordinates.lng,
                        marker.coordinates.lat,
                    ],
                    type: 'Point',
                },
                requesterUserId: this.props.currentUserId,
                sketchOptions: this.state.selectedBuildingType,
                metalRoofPanelSize: this.state.selectedMetalPanelSize,
                includeGutters: this.state.includeGutters,
                gutterSize: this.state.selectedGutterSize,
                officeId: this.props.sosDeliveryOfficeId,
                rushed: this.state.rushed,
            };
            const newSketchOrder = {
                ...baseOrder,
                sketchReportType: this.state.sketchReportType,
            };
            sketchOrders.push(newSketchOrder);
            if (this.state.includeGutters) {
                const gutterOrder = {
                    ...baseOrder,
                    sketchReportType: OrderTypes.Gutters,
                };
                sketchOrders.push(gutterOrder);
            }
        });

        const response = await this.props.dispatch(
            createSketchOrders(sketchOrders, this.getPaymentType(), nonce)
        );
        if (response.type !== CREATE_BULK_SKETCH_ORDER_SUCCESS) {
            this.props.dispatch(
                setReduxMessage('Error creating sketch order(s).')
            );
        } else {
            try {
                (window as any).Appcues.track('Created a Sketch Order');
            } catch (error) {
                LoggerWrapper.captureException(error);
            }
            // this is here to signal to google analytics that an order was successfully ordered
            this.props.history.push(
                `/orders/v2/create/${this.state.sketchReportType}/complete`
            );
            await sleep(50); // HACK: here to let history trigger catch change and push to GA

            // now replace the complete url so users can NOT hit their back button and go back to this url and
            // trigger another GA order event

            this.props.history.replace(
                '/orders/v2/create/complete',
                response.response[0].rushed
            );

            // clear out redux data
            this.props.dispatch(clearMarkers());
            this.props.dispatch(clearAddress());
        }
    };
    handleNext = () => {
        if (
            this.state.activeStep === 0 &&
            this.getExistingSketchOrdersFromState().length > 0
        ) {
            this.setState({ showExistingOrderWarning: true });
        } else {
            this.setState({
                showExistingOrderWarning: false,
                activeStep: this.state.activeStep + 1,
            });
        }
    };
    handleSubmit = async () => {
        let nonce: string = '';
        if (
            this.state.paymentType === PAYMENT_TYPE.USER &&
            this.state.getNonce
        ) {
            nonce = await this.state.getNonce();
        }

        await this.createSketchOrder(nonce);
    };
    handleBack = () => {
        this.setState({
            paymentType: PAYMENT_TYPE.None,
            paymentValid: false,
            getNonce: undefined,
            activeStep: this.state.activeStep - 1,
        });
    };
    handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ [event.target.name]: event.target.value } as any);
    };
    handleRushedChanged = (rushed: boolean) => {
        this.setState({
            rushed,
        });
    };
    handleOwnerChange = (ownerUserId: number) => {
        this.setState({ ownerUserId });
    };
    handleZoomChanged = (zoom: number) => {
        if (this.props.markers.length === 0) return;
        this.setState({ zoom });
    };
    handleCenterChanged = (center: any) => {
        if (this.props.markers.length === 0) return;
        this.setState({
            center: {
                lat: center.lat(),
                lng: center.lng(),
            },
        });
    };
    handleAddressChanged = (address: string) => {
        this.props.dispatch(geocodeAddress(address));
    };
    handleMarkerAdded = (marker: google.maps.Marker) => {
        this.props.dispatch(addMarker(marker));
    };
    handleMarkerUpdated = (marker: google.maps.Marker) => {
        this.props.dispatch(updateMarker(marker));
    };
    handleMarkerRemoved = (marker: google.maps.Marker) => {
        if ((marker as any).type === EXISTING_SKETCHOS_ORDER_MARKER_TYPE) {
            return;
        }

        this.props.dispatch(removeMarker(marker));
    };
    // eslint-disable-next-line no-unused-vars
    handleMarkerClick = (
        event: google.maps.MouseEvent,
        marker: google.maps.Marker
    ) => {
        if ((marker as any).type === EXISTING_SKETCHOS_ORDER_MARKER_TYPE) {
            this.setState({ showInfoWindowForMarkerId: (marker as any).id });
        }
    };
    handleInfoWindowClosed = () => {
        this.setState({ showInfoWindowForMarkerId: undefined });
    };
    handleBuildingTypeSelected = (
        buildingType: BuildingTypes,
        notesRequired: boolean,
        buildingTypeCategory: BuildingTypeCategories
    ) => {
        this.setState({
            selectedBuildingType: buildingType,
            notesRequired,
            buildingTypeCategory: buildingTypeCategory,
        });
    };

    handleMetalOptionsChange = (selectedMetalPanelSize: number | undefined) => {
        this.setState({ selectedMetalPanelSize });
    };

    handleIncludeGutters = (includeGutters: boolean) => {
        this.setState({ includeGutters });
    };

    handleGutterOptionsChange = (selectedGutterSize: number | undefined) => {
        this.setState({ selectedGutterSize });
    };

    handleSketchReportTypeChange = (sketchReportType: AllowedOrderTypes) => {
        this.setState({ sketchReportType });
    };

    isFinalStep = (activeStep: number) =>
        activeStep === 1 && this.state.sketchReportType;

    isOrderFree() {
        return (
            this.props.markers.length <=
            this.props.freeSketchOrderBalance - this.props.openSketchOrders
        );
    }
    getIsRushAvailable = (): boolean => {
        return (
            this.state.isSketchTechAvailable &&
            this.props.markers.length < 2 &&
            this.props.rushOrdering &&
            this.state.sketchReportType !== OrderTypes.MetalRoof
        );
    };

    checkSketchTechAvailable = async () => {
        const schedule = await getTodaysSchedule();
        if (!schedule) {
            this.setState({ isSketchTechAvailable: false });
        } else if (
            !schedule.startTime ||
            !schedule.endTime ||
            !schedule.currentServerTime
        ) {
            this.setState({ isSketchTechAvailable: false });
        } else {
            const isSketchTechAvailable =
                schedule.currentServerTime >= schedule.startTime &&
                schedule.currentServerTime < schedule.endTime;
            this.setState({
                isSketchTechAvailable,
            });
        }
    };

    isInvalidBuildingType(): boolean {
        return (
            this.state.selectedBuildingType === BuildingTypes.None ||
            (this.state.selectedBuildingType === BuildingTypes.Other &&
                !this.state.notes)
        );
    }
    isInvalidGutterSize(): boolean {
        return this.state.selectedGutterSize === undefined;
    }
    isInvalidMetalPanelSize(): boolean {
        return this.state.selectedMetalPanelSize === undefined;
    }

    getNextDisabled(): boolean {
        if (this.state.activeStep === 0) {
            return (
                this.props.markers.length === 0 ||
                this.state.fetchingExistingOrders ||
                (this.state.notesRequired && !this.state.notes) ||
                (this.state.sketchReportType === OrderTypes.Gutters &&
                    this.isInvalidGutterSize()) ||
                (this.state.includeGutters && this.isInvalidGutterSize()) ||
                (this.state.sketchReportType === OrderTypes.MetalRoof &&
                    this.isInvalidMetalPanelSize())
            );
        } else {
            return false;
        }
    }
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Grid
                    container
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        rowGap: 10,
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        lg={9}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Paper className={classes.paper}>
                            {this.state.activeStep === 0 && (
                                <Grid
                                    container
                                    direction='column'
                                    wrap='nowrap'
                                >
                                    <Grid item>
                                        <Grid container>
                                            <Grid item>
                                                <Typography
                                                    variant='h6'
                                                    className={
                                                        classes.stepTitle
                                                    }
                                                >
                                                    {this.state
                                                        .sketchReportType ===
                                                    'gutters'
                                                        ? 'New gutter measurement order V2'
                                                        : 'New measurement order V2'}
                                                </Typography>
                                                <Typography
                                                    variant='body1'
                                                    className={
                                                        classes.stepSubtitle
                                                    }
                                                >
                                                    Search for an address and
                                                    move the pin to the correct
                                                    building. For apartment
                                                    complexes or condos, click
                                                    to drop multiple pins on
                                                    each structure. Each pin
                                                    will create a separate
                                                    order. Double click a pin to
                                                    remove it.
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                {/* <MapIconLegend /> */}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Location
                                            onAddressChanged={
                                                this.handleAddressChanged
                                            }
                                            markers={this.getMarkersToShow()}
                                            onMarkerAdded={
                                                this.handleMarkerAdded
                                            }
                                            onMarkerUpdated={
                                                this.handleMarkerUpdated
                                            }
                                            onMarkerRemoved={
                                                this.handleMarkerRemoved
                                            }
                                            onMarkerClick={
                                                this.handleMarkerClick
                                            }
                                            onMarkerInfoWindowClosed={
                                                this.handleInfoWindowClosed
                                            }
                                            center={this.state.center}
                                            zoom={this.state.zoom}
                                            onZoomChanged={
                                                this.handleZoomChanged
                                            }
                                            onCenterChanged={
                                                this.handleCenterChanged
                                            }
                                        />
                                    </Grid>
                                    <OrderSection
                                        className={classes.orderSection}
                                    >
                                        <Grid item md={6} xs={12}>
                                            <BuildingTypeSelector
                                                onChange={
                                                    this
                                                        .handleBuildingTypeSelected
                                                }
                                                value={
                                                    this.state
                                                        .selectedBuildingType
                                                }
                                                buildingTypeCategory={
                                                    this.state
                                                        .buildingTypeCategory
                                                }
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Notes
                                                onChange={(notes) =>
                                                    this.setState({ notes })
                                                }
                                                required={
                                                    this.state.notesRequired
                                                }
                                                value={this.state.notes}
                                            />
                                        </Grid>
                                    </OrderSection>
                                    <OrderReportTypes
                                        selectedMetalPanelSize={
                                            this.state.selectedMetalPanelSize
                                        }
                                        onMetalSizeOptionsChange={
                                            this.handleMetalOptionsChange
                                        }
                                        selectedGutterSize={
                                            this.state.selectedGutterSize
                                        }
                                        onGutterOptionsChange={
                                            this.handleGutterOptionsChange
                                        }
                                        onSketchReportTypeChange={
                                            this.handleSketchReportTypeChange
                                        }
                                        sketchReportType={
                                            this.state.sketchReportType
                                        }
                                        includeGutters={
                                            this.state.includeGutters
                                        }
                                        handleIncludeGutters={
                                            this.handleIncludeGutters
                                        }
                                    ></OrderReportTypes>
                                    <Grid item xs container direction='column'>
                                        <Grid item>
                                            <SketchOrderProjectDetails
                                                users={this.props.users}
                                                projectName={
                                                    this.state.projectName
                                                }
                                                ownerUserId={
                                                    this.state.ownerUserId
                                                }
                                                onChange={this.handleOnChange}
                                                onOwnerChange={
                                                    this.handleOwnerChange
                                                }
                                                onMetalSizeOptionsChange={
                                                    this
                                                        .handleMetalOptionsChange
                                                }
                                                selectedMetalPanelSize={
                                                    this.state
                                                        .selectedMetalPanelSize
                                                }
                                                onGutterOptionsChange={
                                                    this
                                                        .handleGutterOptionsChange
                                                }
                                                selectedGutterSize={
                                                    this.state
                                                        .selectedGutterSize
                                                }
                                                showRequiredHelperText={
                                                    this.state
                                                        .showProjectDetailsHelperText
                                                }
                                                sketchReportType={`${this.state.sketchReportType}`}
                                                includesGutters={
                                                    this.state.includeGutters
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            {this.state.activeStep === 1 && (
                                <PaymentStep
                                    onPaymentChange={this.onPaymentChange}
                                    isOrderFree={this.isOrderFree()}
                                    isRushed={this.state.rushed}
                                    numberOfOrdersBeingPlaced={
                                        this.props.markers.length
                                    }
                                    freeSketchOrderBalance={
                                        this.props.freeSketchOrderBalance
                                    }
                                    openSketchOrders={
                                        this.props.openSketchOrders
                                    }
                                />
                            )}
                            <Grid style={{ margin: '30px 0' }}>
                                <Stepper
                                    activeStep={this.state.activeStep}
                                    alternativeLabel
                                    className={classes.stepper}
                                >
                                    <Step
                                        key='markers'
                                        className={classes.stepContainer}
                                    >
                                        <StepLabel>
                                            <Typography
                                                className={classes.stepLabel}
                                            >
                                                Mark locations
                                            </Typography>
                                        </StepLabel>
                                    </Step>
                                    <Step
                                        key='payment'
                                        className={classes.stepContainer}
                                    >
                                        <StepLabel>
                                            <Typography
                                                className={classes.stepLabel}
                                            >
                                                Payment
                                            </Typography>
                                        </StepLabel>
                                    </Step>
                                </Stepper>
                            </Grid>
                            <Grid container justifyContent='flex-end'>
                                <Grid item>
                                    <Button
                                        disabled={this.state.activeStep === 0}
                                        onClick={this.handleBack}
                                        className={classes.backButton}
                                    >
                                        Back
                                    </Button>
                                    {this.isFinalStep(
                                        this.state.activeStep
                                    ) && (
                                        <Button
                                            id='order-submit-button'
                                            color='primary'
                                            variant='contained'
                                            disabled={
                                                !(
                                                    this.state.paymentType &&
                                                    this.state.paymentValid
                                                )
                                            }
                                            onClick={this.handleSubmit}
                                        >
                                            {this.props.markers.length === 1
                                                ? 'Submit Order'
                                                : `Submit ${this.props.markers.length} Orders`}
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        lg={3}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        {this.state.activeStep === 0 && (
                            <OrderSummary
                                onCheckoutClick={this.handleNext}
                                address={this.state.address}
                                city={this.state.city}
                                region={this.state.region}
                                postcode={this.state.postcode}
                                country={this.state.country}
                                sketchReportType={this.state.sketchReportType}
                                numberOfOrdersBeingPlaced={
                                    this.props.markers.length
                                }
                                freeSketchOrderBalance={
                                    this.props.freeSketchOrderBalance
                                }
                                openSketchOrders={this.props.openSketchOrders}
                                orgId={this.props.organizationId}
                                rushed={this.state.rushed}
                                handleRushedChanged={this.handleRushedChanged}
                                rushOrdering={this.props.rushOrdering}
                                isBusinessHours={
                                    this.state.isSketchTechAvailable ?? false
                                }
                                isRushAvailable={this.getIsRushAvailable()}
                                includeGutters={this.state.includeGutters}
                                selectedGutterSize={
                                    this.state.selectedGutterSize
                                }
                                selectedMetalPanelSize={
                                    this.state.selectedMetalPanelSize
                                }
                                checkoutDisabled={
                                    this.getNextDisabled() &&
                                    !(
                                        this.state.paymentType &&
                                        this.state.paymentValid
                                    )
                                }
                            />
                        )}
                    </Grid>
                </Grid>

                <Dialog
                    onClose={(_, reason) => reason !== 'backdropClick'}
                    open={this.state.showExistingOrderWarning}
                    disableEscapeKeyDown
                >
                    <DialogTitle id='sketchos-duplicate-warning'>
                        Warning
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            It appears that one of your orders is within 25
                            meters of an existing SketchOS you have already
                            ordered. If this is OK, please press the Proceed
                            button to continue. If not, please review for
                            possible duplicate order.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color='secondary'
                            onClick={this.onClickDialog(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            id='proximity-warning-proceed-button'
                            color='primary'
                            onClick={this.onClickDialog(true)}
                        >
                            Proceed
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    const {
        currentUser,
        organization,
        sketchOsGeocoding,
        organizationUsers,
        organizationFreeSketchOrders,
        SketchOSOfficeIdReducer,
        organizationFeatures,
    } = state;
    const {
        address: sketchOsGeocodingAddress,
        coordinates,
        markers,
    } = sketchOsGeocoding;
    const {
        addressLine1: address,
        city,
        state: region,
        postalCode: postcode,
        country,
    } = sketchOsGeocodingAddress;
    const { isFetching } = state;
    const { currentUserRoles } = state;
    const { data: users } = organizationUsers;
    const { organizationId, userId: currentUserId } = currentUser;
    const { freeSketchOrderBalance, openSketchOrders } =
        organizationFreeSketchOrders;
    const { sketchOSOfficeId } = SketchOSOfficeIdReducer;
    const { rushOrdering } = organizationFeatures;

    return {
        address,
        city,
        region,
        postcode,
        country,
        markers,
        coordinates,
        currentUserId,
        organizationId,
        organization,
        currentUser,
        currentUserRoles,
        freeSketchOrderBalance,
        openSketchOrders,
        users: orderBy(
            users,
            [(user) => user.userName.toLowerCase()],
            ['asc']
        ).filter((user) => user.active === true),
        sosDeliveryOfficeId: sketchOSOfficeId,
        rushOrdering,
        isFetching,
    };
};

export default withRouter(
    connect(mapStateToProps)(
        withStyles(styles, { withTheme: true })(CreateSketchOrderPage)
    )
);

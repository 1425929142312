import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
    Box,
    Button,
    ButtonProps,
    Checkbox,
    Container,
    Divider,
    Grid,
    Paper,
    Stack,
    styled,
    Typography,
} from '@mui/material';
import * as React from 'react';
import { PropsWithChildren, useState } from 'react';
import { useHistory } from 'react-router';
import {
    OrderTypes,
    AllowedOrderTypes,
    PriceGridType,
} from '../../lib/MeasurementOrders';
import featureFlags from 'lib/FeatureFlags';
import { PriceGrid } from 'lib/Models/PriceGrid';
import { mapPricingStateToProps } from 'lib/util/PricingGridState';
import { connect } from 'react-redux';
const ColorButton = styled(Button)<ButtonProps>(() => ({
    margin: 0,
    backgroundColor: '#38BF41',
    borderRadius: 4,
    width: 130,
    height: 40,
    opacity: 1,
    color: '#FFFFFF',
    '&:hover': {
        backgroundColor: '#30B439',
        color: '#FFFFFF',
    },
}));
interface OrderPanelProps {
    title: string;
    subtitle: string;
    rateLabel: string;
    rate: number;
    orderType: AllowedOrderTypes;
    enableGutters?: boolean;
    priceGrids: { [key in PriceGridType]: PriceGrid[] };
    openPricingPopover: (
        event: React.MouseEvent<HTMLDivElement>,
        pricingType: PriceGridType
    ) => void;
}
const OrderPanel = (props: PropsWithChildren<OrderPanelProps>) => {
    const {
        title,
        subtitle,
        rateLabel,
        rate,
        orderType,
        enableGutters = false,
        priceGrids,
        openPricingPopover: openPricePopover,
        children,
    } = props;
    const [addGutterReport, setAddGutterReport] = useState(false);
    const history = useHistory();

    const handleOrderClick = () => {
        let queryString = '';
        if (
            addGutterReport &&
            (orderType === OrderTypes.FullSnap ||
                orderType === OrderTypes.MetalRoof)
        ) {
            queryString += '?gutters';
        }
        history.push(`create/${orderType}${queryString}`);
    };
    const openPriceTable = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        if (orderType === OrderTypes.FullSnap) {
            openPricePopover(event, 'FullSnap');
        } else if (orderType === OrderTypes.MetalRoof) {
            openPricePopover(event, 'MetalRoof');
        }
    };
    return (
        <Paper
            sx={{
                width: 375,
                borderRadius: 1,
                border: '1px solid #DDDDDD',
                boxShadow: '0px 1px 1px #00000048',
                margin: 'auto',
            }}
            variant='outlined'
        >
            <Container sx={{ mt: 3 }}>
                <Stack sx={{ pb: 4 }}>
                    <Stack
                        sx={{
                            height: 488.75,
                            pb: 1,
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box>
                            <img
                                width={'100%'}
                                src={`/assets/${orderType}.svg`}
                                alt={orderType}
                            />
                            {(orderType === OrderTypes.HalfSnap ||
                                orderType === OrderTypes.FullSnap) && (
                                <Typography
                                    variant='subtitle2'
                                    sx={{
                                        mt: 2,
                                        color: '#999A9A',
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    {subtitle}
                                </Typography>
                            )}
                            {(orderType === OrderTypes.MetalRoof ||
                                orderType === OrderTypes.Gutters) && (
                                <Typography
                                    variant='subtitle2'
                                    sx={{
                                        mt: 2,
                                        color: '#EB3C3B',
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    {subtitle}
                                </Typography>
                            )}
                            <Typography
                                variant='h5'
                                sx={{
                                    mt: 1,
                                    fontWeight: 'medium',
                                }}
                            >
                                {title}
                            </Typography>
                            <Box sx={{ mt: 2 }}>{children}</Box>
                        </Box>
                        <Stack
                            sx={{ ml: 3.5 }}
                            direction={'row'}
                            alignItems={'center'}
                        >
                            <Typography>{rateLabel}</Typography>
                            <Typography
                                sx={{
                                    ml: 1,
                                    fontSize: 18,
                                    fontWeight: 'bold',
                                    color: '#046CF1',
                                }}
                            >
                                {'$' +
                                    rate.toString() +
                                    (orderType === OrderTypes.FullSnap ||
                                    orderType === OrderTypes.MetalRoof
                                        ? '+'
                                        : '')}
                            </Typography>
                            {(orderType === OrderTypes.FullSnap ||
                                orderType === OrderTypes.MetalRoof) && (
                                <div onClick={(e) => openPriceTable(e)}>
                                    <InfoOutlinedIcon
                                        sx={{
                                            ml: 1,
                                            fontSize: 16,
                                            color: '#999A9A',
                                        }}
                                    />
                                </div>
                            )}
                        </Stack>
                    </Stack>
                    <Box height={66}>
                        {(orderType === OrderTypes.FullSnap ||
                            orderType === OrderTypes.MetalRoof) &&
                            featureFlags.gutters &&
                            enableGutters && (
                                <Box>
                                    <Box
                                        sx={{
                                            pt: 3,
                                            pb: 3,
                                            pl: 2,
                                            pr: 2,
                                            borderTop: addGutterReport ? 2 : 1,
                                            borderLeft: addGutterReport ? 2 : 0,
                                            borderBottom: addGutterReport
                                                ? 2
                                                : 0,
                                            borderRight: addGutterReport
                                                ? 2
                                                : 0,
                                            borderColor: addGutterReport
                                                ? '#046CF1'
                                                : '#E1E1E1',
                                            borderRadius: addGutterReport
                                                ? 2
                                                : 0,
                                            background: addGutterReport
                                                ? '#F8FCFF'
                                                : '#FFFFFF',
                                            boxShadow: addGutterReport
                                                ? '0px 0px 6px #046CF14D'
                                                : '',
                                        }}
                                    >
                                        <Stack
                                            direction={'row'}
                                            justifyContent={'space-between'}
                                        >
                                            <Stack
                                                direction={'row'}
                                                alignItems={'center'}
                                            >
                                                <Checkbox
                                                    sx={{ padding: 0 }}
                                                    checked={addGutterReport}
                                                    onClick={() => {
                                                        setAddGutterReport(
                                                            !addGutterReport
                                                        );
                                                    }}
                                                />
                                                <Typography
                                                    sx={{
                                                        ml: 1.5,
                                                        mt: 0.1,
                                                        fontSize:
                                                            addGutterReport
                                                                ? 18
                                                                : 16,
                                                        fontWeight:
                                                            addGutterReport
                                                                ? 'medium'
                                                                : 'normal',
                                                        color: '#1F1F1F',
                                                    }}
                                                >
                                                    Add Gutter Report
                                                </Typography>
                                            </Stack>
                                            <Typography
                                                sx={{
                                                    mt: 0.1,
                                                    fontSize: addGutterReport
                                                        ? 18
                                                        : 16,
                                                    fontWeight: addGutterReport
                                                        ? 'medium'
                                                        : 'normal',
                                                }}
                                            >
                                                {'$' +
                                                    priceGrids.Gutters[0]
                                                        .tierPrices[0].price}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                </Box>
                            )}
                    </Box>
                    {!addGutterReport && <Divider></Divider>}
                    <Grid container sx={{ mt: 4 }} justifyContent='flex-end'>
                        <ColorButton onClick={handleOrderClick}>
                            Order Now
                        </ColorButton>
                    </Grid>
                </Stack>
            </Container>
        </Paper>
    );
};

export default connect(mapPricingStateToProps)(OrderPanel);

import {
    Box,
    createStyles,
    Grid,
    makeStyles,
    Radio,
    Typography,
} from '@material-ui/core';
import { List, ListItem } from '@mui/material';
import React, { useState } from 'react';

import {
    OrderTypes,
    OrderTypeTitle,
    OrderTypeToString,
    PriceGridType,
} from 'lib/MeasurementOrders';
import classNames from 'classnames';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PricingPopover from 'components/MeasurementOrder/v2/PricingPopover';
import { PriceGrid } from 'lib/Models/PriceGrid';
import { mapPricingStateToProps } from 'lib/util/PricingGridState';
import { connect } from 'react-redux';
import { OrderTypeInfo } from './OrderTypeInfo';
import MetalPanelSize from './MetalPanelSize';
import GutterPanelSize from './GutterPanelSize';
import AddGutterOrder from './AddGutterOrder';

type OrderTypeProps = {
    onMetalSizeOptionsChange?: (metalOptions: number | undefined) => void;
    selectedMetalPanelSize: number | undefined;
    onGutterOptionsChange?: (gutterOptions: number | undefined) => void;
    selectedGutterSize: number | undefined;
    reportType: string;
    selectedOrderType: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    priceGrids: { [key in PriceGridType]: PriceGrid[] };
    includeGutters?: boolean;
    handleIncludeGutters: (includeGutters: boolean) => void;
};

const useStyles = makeStyles(() =>
    createStyles({
        reportTypeTitle: {
            color: '#555',
            fontSize: '0.6875rem',
            fontWeight: 500,
            letterSpacing: '0.03125rem',
            textTransform: 'uppercase',
        },
        reportTypeSubTitle: {
            color: '#222',
            fontSize: '1rem',
            fontWeight: 500,
            lineHeight: '1.5rem',
            letterSpacing: '0.00938rem',
            marginTop: '4px',
        },
        reportTypeInfo: {
            color: '#222',
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: '1.25rem',
            letterSpacing: '0.01563rem',
            fontFamily: 'Roboto',
        },
        newOrderType: {
            color: 'red',
            fontWeight: 500,
        },
        orderRateLabel: {
            color: '#056CF2',
            fontSize: '1.2rem',
            fontWeight: 700,
            lineHeight: '1.5rem',
            letterSpacing: '0.03125rem',
        },
        selectedOrderTypeSection: {
            width: '100%',
            paddingTop: '10px',
            paddingBottom: '10px',
            borderRadius: '0.25rem',
            border: '2px solid #056CF2',
            background: '#FFF',
            boxShadow: '0px 0px 12px 0px rgba(5, 108, 242, 0.30)',
        },
        orderTypeSection: {
            width: '100%',
            paddingTop: '10px',
            paddingBottom: '10px',
            borderRadius: '0.25rem',
            border: '1px solid #CCC',
            boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.35)',
        },
    })
);

const OrderTypeRateLabels: { [key: string]: string } = {
    fullsnap: 'from',
    halfsnap: 'flat rate',
    metalroof: 'from',
    gutters: 'flat rate',
};

const OrderType = ({
    reportType,
    selectedOrderType,
    onChange,
    priceGrids,
    selectedMetalPanelSize,
    onMetalSizeOptionsChange,
    selectedGutterSize,
    onGutterOptionsChange,
    includeGutters,
    handleIncludeGutters,
}: OrderTypeProps) => {
    let _reportType = reportType;
    const reportDetails = OrderTypeInfo[reportType];
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [pricingType, setPricingType] = useState<PriceGridType>('FullSnap');

    const pricingPopoverOpen = Boolean(anchorEl);

    const openPricingPopover = (
        event: React.MouseEvent<HTMLElement>,
        pricingType: PriceGridType
    ) => {
        setPricingType(pricingType);
        setAnchorEl(event.currentTarget);
    };

    const openPriceTable = (
        event: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        if (reportType === OrderTypes.FullSnap) {
            openPricingPopover(event, 'FullSnap');
        } else if (reportType === OrderTypes.MetalRoof) {
            openPricingPopover(event, 'MetalRoof');
        }
    };

    const getOrderBasePrice = (reportType: string): string => {
        let basePrice = 0;
        switch (reportType) {
            case OrderTypes.MetalRoof:
                basePrice = priceGrids.MetalRoof[0].tierPrices[0].price;
                break;
            case OrderTypes.Gutters:
                basePrice = priceGrids.Gutters[0].tierPrices[0].price;
                break;
            case OrderTypes.FullSnap:
                basePrice = priceGrids.FullSnap[0].tierPrices[0].price;
                break;
            case OrderTypes.HalfSnap:
                basePrice = priceGrids.HalfSnap[0].tierPrices[0].price;
        }
        return '$' + basePrice.toString();
    };

    const isNewOrderType =
        reportType === OrderTypes.MetalRoof ||
        reportType === OrderTypes.Gutters;

    const showInfoIcon =
        reportType === OrderTypes.MetalRoof ||
        reportType === OrderTypes.FullSnap;

    return (
        <Box
            className={
                selectedOrderType === reportType
                    ? classes.selectedOrderTypeSection
                    : classes.orderTypeSection
            }
        >
            <Grid container direction='row' alignItems='stretch'>
                <Grid
                    xs={1}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Radio
                        name='orderTypeRadioButtons'
                        checked={selectedOrderType === reportType}
                        value={reportType}
                        onChange={onChange}
                    ></Radio>
                </Grid>
                <Grid xs={12} md={3}>
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            src={`/assets/${reportType}.svg`}
                            height='97%'
                            width='97%'
                            alt={reportType}
                        />
                    </Box>
                </Grid>
                <Grid direction='row' xs={8} md={6}>
                    <Box sx={{ paddingLeft: '10px' }}>
                        <Typography
                            className={classNames(
                                classes.reportTypeTitle,
                                isNewOrderType ? classes.newOrderType : ''
                            )}
                        >
                            {isNewOrderType
                                ? 'New'
                                : OrderTypeToString[reportType]}
                        </Typography>
                        <Typography className={classes.reportTypeSubTitle}>
                            {OrderTypeTitle[reportType]}
                        </Typography>
                        <List
                            sx={{
                                listStyle: 'disc',
                                paddingTop: '5px',
                                paddingLeft: '25px',
                            }}
                        >
                            {reportDetails.map((reportInfo) => {
                                return (
                                    <ListItem
                                        className={classes.reportTypeInfo}
                                        sx={{
                                            display: 'list-item',
                                            paddingTop: '0px',
                                            paddingBottom: '0px',
                                            paddingLeft: 0,
                                        }}
                                    >
                                        {reportInfo}
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Box>
                </Grid>
                <Grid
                    item
                    style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-end',
                        flexGrow: 1,
                    }}
                >
                    <Box
                        paddingRight={'10px'}
                        display={'flex'}
                        marginTop={'5px'}
                    >
                        <Typography style={{ paddingRight: '5px' }}>
                            {OrderTypeRateLabels[reportType]}
                        </Typography>
                        <Typography className={classes.orderRateLabel}>
                            {getOrderBasePrice(reportType)}
                        </Typography>
                        {showInfoIcon && (
                            <>
                                <Typography className={classes.orderRateLabel}>
                                    +
                                </Typography>
                                <Box
                                    style={{ display: 'inline-block' }}
                                    onClick={(e) => openPriceTable(e)}
                                >
                                    <InfoOutlinedIcon
                                        sx={{
                                            ml: '4px',
                                            fontSize: '23px',
                                            color: '#999A9A',
                                            marginBottom: '-3px',
                                            cursor: 'pointer',
                                        }}
                                    />
                                </Box>
                            </>
                        )}
                    </Box>
                </Grid>
            </Grid>
            {_reportType === selectedOrderType && (
                <Grid
                    container
                    direction='row'
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        paddingLeft: '15px',
                    }}
                >
                    <Grid xs={12} md={8} item>
                        {reportType === OrderTypes.FullSnap && (
                            <AddGutterOrder
                                onGutterOptionsChange={onGutterOptionsChange}
                                selectedGutterSize={selectedGutterSize}
                                gutterPrice={getOrderBasePrice(
                                    OrderTypes.Gutters
                                )}
                                reportType={reportType}
                                selectedOrderType={selectedOrderType}
                                includeGutters={includeGutters}
                                handleIncludeGutters={handleIncludeGutters}
                            />
                        )}
                        {reportType === OrderTypes.MetalRoof && (
                            <>
                                <MetalPanelSize
                                    onMetalSizeOptionsChange={
                                        onMetalSizeOptionsChange
                                    }
                                    selectedMetalPanelSize={
                                        selectedMetalPanelSize
                                    }
                                />
                                <Box sx={{ marginTop: '5px' }}></Box>
                                <AddGutterOrder
                                    onGutterOptionsChange={
                                        onGutterOptionsChange
                                    }
                                    selectedGutterSize={selectedGutterSize}
                                    gutterPrice={getOrderBasePrice(
                                        OrderTypes.Gutters
                                    )}
                                    reportType={reportType}
                                    selectedOrderType={selectedOrderType}
                                    includeGutters={includeGutters}
                                    handleIncludeGutters={handleIncludeGutters}
                                />
                            </>
                        )}

                        {reportType === OrderTypes.Gutters && (
                            <GutterPanelSize
                                onGutterOptionsChange={onGutterOptionsChange}
                                selectedGutterSize={selectedGutterSize}
                                reportType={reportType}
                                selectedOrderType={selectedOrderType}
                            />
                        )}
                    </Grid>
                </Grid>
            )}
            <PricingPopover
                open={pricingPopoverOpen}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                priceGridType={pricingType}
            ></PricingPopover>
        </Box>
    );
};

export default connect(mapPricingStateToProps)(OrderType);

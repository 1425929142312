import * as React from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import { Stack, Typography } from '@mui/material';

interface OrderFeatureProps {
  text: string;
}
const OrderFeature = (props: OrderFeatureProps) => {
  const { text } = props;
  return (
    <Stack direction={'row'} alignItems={'center'}>
      <CircleIcon
        sx={{
          fontSize: 6,
        }}
      />
      <Typography sx={{ ml: 2 }}>{text}</Typography>
    </Stack>
  );
};
export default OrderFeature;

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { generatePaymentClientToken } from '../../../../actions/Payment';
import OrganizationStatus from '../../../../lib/OrganizationStatus';
import PAYMENT_TYPE, { PaymentType } from '../../../../lib/PaymentType';
import PaymentSummaryContent from '../../../PaymentSummaryContent';
import SubscriptionBillingLockedDownMessage from './SubscriptionBillingLockedDownMessage';
import { RoofSnapState } from 'lib/Models/RoofSnapState';

type PaymentStepProps = {
    isOrderFree: boolean;
    isRushed: boolean;
    displayUserBillingOnly?: boolean;
    errorMessage?: string;
    numberOfOrdersBeingPlaced: number;
    freeSketchOrderBalance: number;
    openSketchOrders: number;
    onPaymentChange: (
        paymentType: PaymentType | null,
        paymentValid: boolean,
        getNonce: () => Promise<string>
    ) => void;
};

const PaymentStep: React.FC<PaymentStepProps> = (props) => {
    const brainTreeDropinRef = useRef<any>(null);
    const [paymentType, setPaymentType] = useState<PaymentType | null>(null);
    const [paymentValid, setPaymentValid] = useState<boolean>(false);

    const [paymentChanged, setPaymentChanged] = useState<boolean>(false);

    const dispatch = useDispatch();

    const { payment: { paymentClientToken } } = useSelector((state: RoofSnapState) => state);

    const { currentUser, currentUserRoles, organization } = useSelector(
        (state: RoofSnapState) => state
    );

    const onCreditCardValid = (valid: boolean) => () => {
        setPaymentValid(valid);
        setPaymentChanged(true);
    };

    const onError = () => {
        setPaymentValid(false);
        setPaymentChanged(true);
    };

    const setOrganizationBilling = () => {
        setPaymentType(PAYMENT_TYPE.ORGANIZATION);
        setPaymentValid(true);
        setPaymentChanged(true);
    };

    const setFreeBilling = () => {
        setPaymentType(PAYMENT_TYPE.NONE);
        setPaymentValid(true);
        setPaymentChanged(true);
    };

    const setUserBilling = async () => {
        await dispatch(generatePaymentClientToken(currentUser.userId));
        setPaymentType(PAYMENT_TYPE.USER);
        setPaymentChanged(true);
    };

    /**
     * Host component will call this to get the requested nonce.
     * it may potentially change the dropin ui after its called
     * @returns {Promise<*>}
     */
    const getNonceFromBraintreeDropin = async () => {
        const { nonce } = await brainTreeDropinRef.current.requestPaymentMethod();
        return nonce;
    };

    const setBraintreeInstance = (i: any) => {
        brainTreeDropinRef.current = i;
        if (i && i.isPaymentMethodRequestable()) {
            setPaymentValid(true);
            setPaymentChanged(true);
        }
    };

    const determineIfUserCanBillRoofSnapSubscription = ({
        displayUserBillingOnly,
    }: PaymentStepProps) => {
        // If we want to display user billing only,
        // If the organization is a free account,
        // Or if the add ons are disabled for the organization,
        // Then the user cannot be bill to their roofsnap subscription
        if (
            displayUserBillingOnly ||
            organization.isFreeAccount === true ||
            organization.addOnsDisabled === true
        ) {
            return false;
        }

        // If none of the above limitations are true,
        // Check if the user has the AccountAdmin or the SketchosEnabled roles
        // If so, then the user can bill to their roofsnap subscription
        if (
            currentUserRoles.isAccountAdmin === true ||
            currentUserRoles.sketchosEnabled === true
        ) {
            return true;
        }

        return false;
    };

    const canBillRoofSnapSubscription =
        determineIfUserCanBillRoofSnapSubscription(props);

    useEffect(() => {
        if (props.isOrderFree && !props.isRushed) {
            setFreeBilling();
            return;
        }
        // Only display user billing if the property is set,
        if (props.displayUserBillingOnly) {
            setUserBilling();
        }

        if (canBillRoofSnapSubscription) {
            setOrganizationBilling();
        } else {
            setUserBilling();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (paymentChanged) {
            props.onPaymentChange(
                paymentType,
                paymentValid,
                getNonceFromBraintreeDropin
            );
            setPaymentChanged(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentType, paymentValid, paymentChanged]);

    return (
        <Grid container direction='column' alignItems='stretch'>
            <Grid item>
                <PaymentSummaryContent
                    paymentType={paymentType ?? PAYMENT_TYPE.NONE}
                    paymentClientToken={paymentClientToken}
                    onBraintreeInstance={setBraintreeInstance}
                    onPaymentMethodRequestable={onCreditCardValid(true)}
                    onNoPaymentMethodRequestable={onCreditCardValid(false)}
                    onError={onError}
                    isRushed={props.isRushed}
                    errorMessage={props.errorMessage}
                    isSneakPeekUser={false}
                    isOrderFree={props.isOrderFree}
                    numberOfOrdersBeingPlaced={props.numberOfOrdersBeingPlaced}
                    freeSketchOrderBalance={props.freeSketchOrderBalance}
                    openSketchOrders={props.openSketchOrders}
                />
            </Grid>
            {organization.status !== OrganizationStatus.SneakPeak && (
                <React.Fragment>
                    {(!props.isOrderFree || props.isRushed) && (
                        <Grid
                            item
                            container
                            justifyContent='center'
                            spacing={5}
                        >
                            <Grid item>
                                {paymentType === PAYMENT_TYPE.ORGANIZATION && (
                                    <Button
                                        color='primary'
                                        onClick={setUserBilling}
                                        id='switch-to-user-billing-button'
                                    >
                                        I want to use a different card
                                    </Button>
                                )}
                                {!props.displayUserBillingOnly &&
                                    paymentType === PAYMENT_TYPE.USER && (
                                        <Button
                                            color='primary'
                                            onClick={setOrganizationBilling}
                                            disabled={
                                                !canBillRoofSnapSubscription
                                            }
                                        >
                                            Bill subscription instead
                                        </Button>
                                    )}
                            </Grid>
                            {!props.displayUserBillingOnly &&
                                !canBillRoofSnapSubscription && (
                                    <Grid item>
                                        <SubscriptionBillingLockedDownMessage
                                            organization={organization}
                                            currentUserRoles={currentUserRoles}
                                        />
                                    </Grid>
                                )}
                        </Grid>
                    )}
                </React.Fragment>
            )}
        </Grid>
    );
};

export default PaymentStep;

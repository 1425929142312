import React, { PropsWithChildren } from 'react';
import {
    Theme,
    WithStyles,
    createStyles,
    withStyles,
} from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import numWords from 'num-words';
import Pricing from './Pricing';
import roofsnapTheme from '../../../lib/theme';
import DeliveryOptions from './DeliveryOptions';
import DeliveryTime from './DeliveryTime';
import { AllowedOrderTypes, OrderTypes } from 'lib/MeasurementOrders';

type OrderSummaryProps = {
    sketchReportType: AllowedOrderTypes;
    numberOfOrdersBeingPlaced: number;
    freeSketchOrderBalance: number;
    openSketchOrders: number;
    orgId: number;
    rushed: boolean;
    handleRushedChanged: (rushed: boolean) => void;
    isBusinessHours: boolean;
    isRushAvailable: boolean;
    rushOrdering: boolean;
    includeGutters?: boolean;
} & WithStyles<typeof styles>;

const ExpansionPanel = withStyles({
    root: {
        border: '1px solid rgba(0,0,0,.125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    },
    expanded: {
        margin: 'auto',
    },
})(MuiExpansionPanel);

const styles = (theme: Theme) =>
    createStyles({
        title: {
            margin: '20px 0 0 0',
        },
        subTitle: {
            margin: '0 0 30px 0',
            color: 'rgba(0,0,0,0.54)',
        },
        gridNumber: {
            fontSize: '20px',
            fontWeight: 'bold',
            textAlign: 'center',
            paddingTop: '10px',
        },
        gridNumber2: {
            fontSize: '20px',
            textAlign: 'right',
            paddingTop: '12px',
        },
        freeNumber: {
            fontSize: '20px',
            fontWeight: 'bold',
            textAlign: 'center',
            paddingTop: '10px',
            color: green[500],
        },
        root: {
            width: '100%',
        },
        heading: {
            fontSize: '20px',
        },
        secondaryHeading: {
            fontSize: '15px',
            color: 'rgba(0,0,0,0.54)',
        },
        details: {
            alignItems: 'center',
            backgroundColor: roofsnapTheme.palette.backgroundGrey.main,
            padding: '0px 0px 20px 0px',
        },
        button: {
            position: 'absolute',
            left: theme.spacing(1),
            right: 'unset',
        },
        expansionSummary: {
            paddingLeft: 64,
            boxShadow: '0 0px 8px 0px rgba(0,0,0,0.3)',
        },
        expansionSummaryHeading: {
            width: '100%',
        },
        expansionDivider: {
            width: '100%',
            margin: '20px',
        },
        freeNumbersGrid: {
            width: '70%',
        },
        gridHeading: {
            fontSize: '20px',
            textAlign: 'left',
        },
        gridSubHeading: {
            fontSize: '15px',
            color: 'rgba(0,0,0,0.54)',
            textAlign: 'left',
        },
        gridDescription: {
            paddingLeft: '20px',
        },
        gridDescription2: {
            paddingRight: '20px',
        },
        parentGrid: {
            margin: '30px 0 0',
        },
    });

const OrderSummary = (props: OrderSummaryProps) => {
    const {
        classes,
        sketchReportType,
        numberOfOrdersBeingPlaced,
        freeSketchOrderBalance,
        openSketchOrders,
        orgId,
        rushed,
        handleRushedChanged,
        isBusinessHours,
        isRushAvailable,
        rushOrdering,
        includeGutters,
    } = props;

    let sketchOrdersAvailable = freeSketchOrderBalance - openSketchOrders;
    if (sketchOrdersAvailable == null || sketchOrdersAvailable < 0) {
        sketchOrdersAvailable = 0;
    }

    const numberWordSketchOrdersAvailable =
        sketchOrdersAvailable >= 0 ? numWords(sketchOrdersAvailable) : '';
    const numberWordNumberOfOrders =
        numberOfOrdersBeingPlaced >= 0
            ? numWords(numberOfOrdersBeingPlaced)
            : '';
    const capitalizedNumberWordNumberOfOrders =
        numberWordNumberOfOrders.charAt(0).toUpperCase() +
        numberWordNumberOfOrders.slice(1);

    const showDeliveryTime = sketchReportType === OrderTypes.MetalRoof;
    const showGutters =
        sketchReportType === OrderTypes.Gutters || includeGutters;

    const PricingExpansionPanel = (
        props: PropsWithChildren<{ heading: string; subHeading: string }>
    ) => {
        return (
            <ExpansionPanel defaultExpanded>
                <ExpansionPanelSummary
                    aria-controls='panel1c-content'
                    id='panel1c-header'
                    expandIcon={<ExpandMoreIcon />}
                    IconButtonProps={{
                        classes: {
                            root: classes.button,
                        },
                    }}
                    className={classes.expansionSummary}
                >
                    <div className={classes.expansionSummaryHeading}>
                        <Grid container direction='row'>
                            <Grid item xs={11}>
                                <Typography className={classes.heading}>
                                    {props.heading}
                                </Typography>
                                <Typography
                                    className={classes.secondaryHeading}
                                >
                                    {props.subHeading}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography className={classes.gridNumber}>
                                    {numberOfOrdersBeingPlaced}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                    className={classes.details}
                    style={{ padding: '0 0 5px 0' }}
                >
                    {props.children}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    };

    return (
        <div className={classes.root}>
            {sketchReportType === OrderTypes.HalfSnap && (
                <PricingExpansionPanel
                    heading='Half Snap Sketch Orders'
                    subHeading='Flat rate for any roof of any size.'
                >
                    <Pricing show='HalfSnap' />
                </PricingExpansionPanel>
            )}
            {sketchReportType === OrderTypes.FullSnap && (
                <PricingExpansionPanel
                    heading='Full Snap Sketch Orders'
                    subHeading='Pricing is based on roof size and complexity.'
                >
                    <Pricing show='FullSnap' />
                </PricingExpansionPanel>
            )}
            {sketchReportType === OrderTypes.MetalRoof && (
                <PricingExpansionPanel
                    heading='Metal Roof Report'
                    subHeading='Price is based on roof size and complexity.'
                >
                    <Pricing show='MetalRoof' />
                </PricingExpansionPanel>
            )}
            {showGutters && (
                <PricingExpansionPanel
                    heading='Gutter Report'
                    subHeading='Flat rate'
                >
                    <Pricing show='Gutters' />
                </PricingExpansionPanel>
            )}
            {sketchOrdersAvailable > 0 && (
                <ExpansionPanel defaultExpanded className={classes.root}>
                    <ExpansionPanelSummary
                        aria-controls='panel1c-content'
                        id='panel1c-header'
                        expandIcon={<ExpandMoreIcon />}
                        IconButtonProps={{
                            classes: {
                                root: classes.button,
                            },
                        }}
                        className={classes.expansionSummary}
                    >
                        <Grid
                            container
                            direction='row'
                            className={classes.expansionSummaryHeading}
                        >
                            <Grid item xs={11}>
                                <Typography className={classes.heading}>
                                    You have free Sketch Orders!
                                </Typography>
                                <Typography
                                    className={classes.secondaryHeading}
                                >
                                    {capitalizedNumberWordNumberOfOrders} of
                                    your {numberWordSketchOrdersAvailable}{' '}
                                    remaining free orders will be applied to
                                    this order
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography className={classes.freeNumber}>
                                    {'-'}
                                    {Math.min(
                                        numberOfOrdersBeingPlaced,
                                        sketchOrdersAvailable
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.details}>
                        <Grid
                            container
                            direction='column'
                            alignItems='center'
                            alignContent='center'
                            spacing={0}
                        >
                            <Grid
                                container
                                direction='row'
                                alignItems='center'
                                alignContent='center'
                                justifyContent='center'
                                className={classes.freeNumbersGrid}
                            >
                                <Grid item xs={12} sm={4}>
                                    <Tooltip title='Available balance reflects pending orders'>
                                        <div>
                                            <Typography
                                                className={classes.gridNumber}
                                            >
                                                {sketchOrdersAvailable}
                                            </Typography>
                                            <Typography
                                                className={
                                                    classes.secondaryHeading
                                                }
                                                align='center'
                                            >
                                                Available
                                            </Typography>
                                        </div>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography className={classes.gridNumber}>
                                        {Math.min(
                                            numberOfOrdersBeingPlaced,
                                            sketchOrdersAvailable
                                        )}
                                    </Typography>
                                    <Typography
                                        className={classes.secondaryHeading}
                                        align='center'
                                    >
                                        Used on this order
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography className={classes.gridNumber}>
                                        {Math.max(
                                            sketchOrdersAvailable -
                                                numberOfOrdersBeingPlaced,
                                            0
                                        )}
                                    </Typography>
                                    <Typography
                                        className={classes.secondaryHeading}
                                        align='center'
                                    >
                                        Remaining
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider className={classes.expansionDivider} />
                            <Grid item xs={12}>
                                <Typography
                                    className={classes.secondaryHeading}
                                    align='center'
                                >
                                    Free Sketch Orders will be redeemed as
                                    orders are completed. This may not be in the
                                    order that you place them.
                                </Typography>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            )}

            {showDeliveryTime && <DeliveryTime />}

            {rushOrdering && !showDeliveryTime && (
                <React.Fragment>
                    <DeliveryOptions
                        orgId={orgId}
                        isBusinessHours={isBusinessHours}
                        isRushAvailable={isRushAvailable}
                        rushed={rushed}
                        onDeliveryOptionChanged={handleRushedChanged}
                        sketchReportType={sketchReportType}
                    />
                    <Grid container item>
                        <Typography
                            style={{
                                padding: 10,
                                fontSize: 15,
                                color: '#808080',
                                textAlign: 'center',
                            }}
                        >
                            * Business hours are Mon.-Sat. 9am - 5pm ET. Orders
                            placed outside of business hours will be completed
                            next business day. Rush Delivery not available on
                            orders with 2 or more buildings or pins.
                        </Typography>
                    </Grid>
                </React.Fragment>
            )}
        </div>
    );
};
export default withStyles(styles)(OrderSummary);

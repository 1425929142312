import { Card, CardContent, Grid, Icon, Paper, Radio, Tooltip, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import LoggerWrapper from '../../../lib/Logger';
import useRoofSnapApi from '../../../hooks/useApiHook';

interface DeliveryOptionsProps extends WithStyles<typeof styles> {
    orgId: number,
    isRushAvailable: boolean,
    rushed: boolean,
    onDeliveryOptionChanged: (deliveryOption: boolean) => void,
    isBusinessHours: boolean,
    sketchReportType: string,
}

interface IRushSettingsResponse {
    organizationId: number,
    rushCost: number,
    halfSnapRushCost: number,
    orderCommitmentIntervalMinutes: number,
}

interface IFormattedRushSettings {
    rushCost: string,
    halfSnapRushCost: string,
    orderCommitmentIntervalMinutes: string,
}

const styles = () => createStyles({
    selectedOption: {
        border: '2px solid #0076DE',
        background: '#F8FCFF',
        padding: '15px 7px',
        cursor: 'pointer',
    },
    disabled: {
        background: '#F8F8F8 0% 0% no-repeat padding-box',
        border: '1px solid #DFDFDF',
        padding: '16px 8px',
    },
    disabledText: {
        color: '#757575'
    },
    rushDeliveryIcon: {
        height: 20,
        paddingRight: 10,

    },
    deliveryOptionText: {
        fontWeight: 600,
        color: '#1F1F1F',
    },
    chooseOption: {
        fontWeight: 500,
        color: '#1F1F1F',
    },
    deliveryTimeText: {
        color: '#149718',
        fontWeight: 600,
    }
})



const DeliveryOptions = (props: DeliveryOptionsProps) => {
    const { orgId, isBusinessHours, isRushAvailable, rushed, onDeliveryOptionChanged, classes, sketchReportType } = props;
    const [deliveryOption, setDeliveryOption] = useState<boolean>(rushed);
    const [standardTooltipOpen, setStandardTooltipOpen] = useState<boolean>(false);
    const [rushTooltipOpen, setRushTooltipOpen] = useState<boolean>(false);
    const [orgRushSettings, setOrgRushSettings] = useState<IFormattedRushSettings>({ rushCost: '', halfSnapRushCost: '', orderCommitmentIntervalMinutes: '' })
    const [duringBusinessHours, setDuringBusinessHours] = useState<boolean>(isBusinessHours)

    const api = useRoofSnapApi(`v1/organizations/${orgId}/rushsettings`);

    const rushSlaIntervalText = () => {
        const intervalAsNumber = parseInt(orgRushSettings.orderCommitmentIntervalMinutes);
        if (intervalAsNumber % 60 === 0) {
            if (intervalAsNumber / 60 === 1) return '1 Hour';
            return `${intervalAsNumber / 60} Hours`;
        };
        return `${orgRushSettings.orderCommitmentIntervalMinutes} Minutes`;
    };

    const standardSlaIntervalText = isRushAvailable ? '4 Hours' : '4 hours*';

    const additionalCharge: string = deliveryOption ? `+$${sketchReportType === 'halfsnap' ? orgRushSettings.halfSnapRushCost : orgRushSettings.rushCost}` : 'No Additional Charge';

    const standardDeliveryHelperText = '*from start of next business day'
    const standardDeliveryTooltipText = 'Standard Delivery orders placed outside business hours will be filled within 4 hours of the next business day. Typical business hours are 9-5 Mon-Sat, but may vay vary.'
    const rushDeliveryHelperText = isRushAvailable ? 'Rush Delivery' : 'Rush Delivery currently unavailable'
    const rushDeliveryTooltipText = 'Rush Delivery orders are only available during business hours. Not available on orders with 2+ buildings/pins. Additional fee applied. Service availability may vary.'

    const getDueDate = (isRushed: boolean) => {
        const now = new Date(Date.now())
        const rushDueDate = new Date(Date.now() + parseInt(orgRushSettings.orderCommitmentIntervalMinutes) * 60000)
        const standardDueDate = new Date(Date.now() + 60000 * 240)
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

        if (isRushed) {
            let dueDate = {
                day: rushDueDate.getDay() === now.getDay() ? 'Today' : days[rushDueDate.getDay()],
                time: rushDueDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
            }
            return dueDate;
        } else {
            let dueDate = {
                day: standardDueDate.getDay() === now.getDay() ? 'Today' : days[standardDueDate.getDay()],
                time: standardDueDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
            }
            return dueDate;
        }
    }

    const dueDateText: (isRushed: boolean) => string = (isRushed: boolean) => {

        if (duringBusinessHours != null) {
            return (
                duringBusinessHours ?
                    `${getDueDate(isRushed).day} by ${getDueDate(isRushed).time}` :
                    'Order will be completed next business day'
            )
        } else {
            return (
                ''
            )
        }

    }

    const handleButtonClick = (value: boolean) => {
        if (!isRushAvailable) {
            if (value === false) return;
            setRushTooltipOpen(!rushTooltipOpen);
        } else {
            setDeliveryOption(value);
            onDeliveryOptionChanged(value);
        }
    }

    const handleStandardTooltipClick = () => {
        setStandardTooltipOpen(!standardTooltipOpen);
    }

    const handleRushTooltipClick = () => {
        setRushTooltipOpen(!rushTooltipOpen);
    }

    const rushedOptionClassName = isRushAvailable ?
        (deliveryOption === true ? props.classes.selectedOption : '')
        :
        props.classes.disabled;

    useEffect(() => {
        const getRushSettingsAsync = async () => {
            let response: IRushSettingsResponse;

            try {
                response = await api.get();
                const rushCost = response.rushCost.toFixed(0).toString();
                const halfSnapRushCost = response.halfSnapRushCost.toFixed(0).toString();
                const orderCommitmentIntervalMinutes = response.orderCommitmentIntervalMinutes.toString();
                setOrgRushSettings({ rushCost, halfSnapRushCost, orderCommitmentIntervalMinutes });
            } catch (error: any) {
                console.error(error);
                LoggerWrapper.log(error);
            }
        }
        getRushSettingsAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (duringBusinessHours !== isBusinessHours) setDuringBusinessHours(isBusinessHours);
    }, [duringBusinessHours, isBusinessHours])

    useEffect(() => {
        if (!isRushAvailable) setDeliveryOption(false);
    }, [isRushAvailable])

    return (
        <Card style={{
            padding: 16,
            boxShadow: '0px 3px 6px #00000029',
            border: '1px solid #DFDFDF',
            borderRadius: '4px',
        }}>
            <CardContent>
                <Grid style={{ marginBottom: 24 }} container justifyContent='space-between' alignItems='center'>
                    <Grid item>
                        <Typography className={classes.chooseOption} style={{ fontSize: 18, fontWeight: 'medium' }} variant='h6'>Choose Your Delivery Option</Typography>
                    </Grid>
                    <Grid item>
                        <Typography id='additionalCharge' style={{ fontSize: 18 }} variant='h6'>{additionalCharge}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={6} sm={12} xs={12} style={{ paddingBottom: 0 }}>
                        <Paper
                            variant='outlined'
                            id='standard'
                            className={deliveryOption === false ? classes.selectedOption : ''}
                            style={deliveryOption === false ? {} : { padding: '16px 8px', cursor: 'pointer' }}
                            onClick={() => handleButtonClick(false)}
                        >
                            <Grid container wrap='nowrap'>
                                <Grid item>
                                    <Radio style={{ paddingTop: 0 }} checked={!deliveryOption} />
                                </Grid>
                                <Grid container item direction='column'>
                                    <Grid item>
                                        <Typography variant='subtitle2'>Standard - Free</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={isRushAvailable ? '' : classes.disabledText} variant='body2'>
                                            Approx. <span style={{ margin: 0, fontWeight: 500 }}>{standardSlaIntervalText}</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={isRushAvailable ? classes.deliveryTimeText : classes.disabledText} variant='body2'>
                                            {dueDateText(false)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12} style={{ paddingBottom: 0 }}>
                        <Paper
                            variant='outlined'
                            id='rushed'
                            className={rushedOptionClassName}
                            style={deliveryOption === true ? {} : { padding: '16px 8px', cursor: 'pointer' }}
                            onClick={() => handleButtonClick(true)}
                        >
                            <Grid container wrap='nowrap'>
                                <Grid item>
                                    <Radio style={{ paddingTop: 0 }} disabled={!isRushAvailable} checked={deliveryOption === true} />
                                </Grid>
                                <Grid container item direction='column'>
                                    <Grid container direction='row' item alignItems='center'>
                                        <Grid item>
                                            <img src="/assets/RushDeliveryIcon.svg" alt="Rushed" className={classes.rushDeliveryIcon} />
                                        </Grid>
                                        <Typography className={isRushAvailable ? classes.deliveryOptionText : classes.disabledText} variant='subtitle2'>
                                            <span style={{ fontStyle: 'italic' }}>Rush</span> - {`$${sketchReportType === 'halfsnap' ? orgRushSettings.halfSnapRushCost : orgRushSettings.rushCost}`}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={isRushAvailable ? '' : classes.disabledText} variant='body2'>
                                            Guaranteed in <span style={{ margin: 0, fontWeight: 500 }}>{rushSlaIntervalText()}</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={isRushAvailable ? classes.deliveryTimeText : classes.disabledText} variant='body2'>
                                            {dueDateText(true)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid style={{ paddingTop: 0 }} container item md={6} sm={12} xs={12} justifyContent='flex-end' alignItems='center' alignContent='center'>
                        {!isRushAvailable &&
                            <React.Fragment>
                                <Grid item>
                                    <Typography style={{ color: '#757575' }} variant='caption'>{standardDeliveryHelperText}</Typography>
                                </Grid>
                                <Grid item>
                                    <Tooltip open={standardTooltipOpen} title={standardDeliveryTooltipText} placement='top-end' leaveDelay={5000} disableFocusListener arrow>
                                        <Icon style={{ fontSize: 16, paddingLeft: 8, paddingTop: 4, color: '#757575' }} onClick={handleStandardTooltipClick}>help_outline</Icon>
                                    </Tooltip>
                                </Grid>
                            </React.Fragment>
                        }
                    </Grid>
                    <Grid style={{ paddingTop: 0 }} container item md={6} sm={12} xs={12} justifyContent='flex-end' alignItems='center' alignContent='center'>
                        <Grid item>
                            <Typography style={{ color: '#757575' }} variant='caption'>{rushDeliveryHelperText}</Typography>
                        </Grid>
                        <Grid item>
                            <Tooltip open={rushTooltipOpen} title={rushDeliveryTooltipText} placement='top-end' leaveDelay={5000} disableFocusListener arrow>
                                <Icon style={{ fontSize: 16, paddingLeft: 8, paddingTop: 4, color: '#757575' }} onClick={handleRushTooltipClick}>help_outline</Icon>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default withStyles(styles)(DeliveryOptions);

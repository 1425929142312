import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import OrderPanel from './OrderPanel';
import { OrderTypes, PriceGridType } from '../../lib/MeasurementOrders';
import PricingPopover from './PricingPopover';
import { Grid, GridSize, WithStyles, withStyles } from '@material-ui/core';
import OrderFeature from './OrderFeature';
import featureFlags from '../../lib/FeatureFlags';
import { connect, useSelector } from 'react-redux';
import { PriceGrid } from 'lib/Models/PriceGrid';
import { mapPricingStateToProps } from 'lib/util/PricingGridState';
import { RoofSnapState } from 'lib/Models/RoofSnapState';

const styles = () => {
    const gridCenter = {
        margin: 'auto',
        alignItems: 'center',
    };

    return {
        smallGrid: {
            ...gridCenter,
            maxWidth: 800,
        },

        medGrid: {
            ...gridCenter,
            maxWidth: 1200,
        },

        lgGrid: {
            ...gridCenter,
            maxWidth: 1600,
        },
    };
};

type OrderLandingProps = {
    priceGrids: { [key in PriceGridType]: PriceGrid[] };
} & WithStyles<typeof styles>;
const OrderLanding = (props: OrderLandingProps) => {
    const { classes, priceGrids } = props;
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const [pricingType, setPricingType] = useState<PriceGridType>('FullSnap');
    const openPricingPopover = (
        event: React.MouseEvent<HTMLDivElement>,
        pricingType: PriceGridType
    ) => {
        setPricingType(pricingType);
        setAnchorEl(event.currentTarget);
    };

    const {
        organizationFeatures: { enableMetalRoof, enableGutters },
    } = useSelector((state: RoofSnapState) => state);

    const pricingPopoverOpen = Boolean(anchorEl);
    const isMetalRoofEnabled = featureFlags.metalRoof && enableMetalRoof;
    const areGuttersEnabled = featureFlags.gutters && enableGutters;

    const orderList = [
        <OrderPanel
            title='Basic Report'
            subtitle='Half Snap'
            rateLabel='flat rate'
            rate={priceGrids.HalfSnap[0].tierPrices[0].price}
            orderType={OrderTypes.HalfSnap}
            openPricingPopover={openPricingPopover}
        >
            <OrderFeature text='Basic measurements' />
            <OrderFeature text='Surface area & predominant pitch' />
            <OrderFeature text='Ideal for per-square estimating' />
        </OrderPanel>,
        <OrderPanel
            title='Detailed Report'
            subtitle='Full Snap'
            rateLabel='from'
            rate={priceGrids.FullSnap[0].tierPrices[0].price}
            orderType={OrderTypes.FullSnap}
            enableGutters={enableGutters}
            openPricingPopover={openPricingPopover}
        >
            <OrderFeature text='Detailed measurements' />
            <OrderFeature text='Linear measurements & surface area' />
            <OrderFeature text='Integrated with our estimating platform' />
        </OrderPanel>,
    ];

    if (isMetalRoofEnabled) {
        orderList.push(
            <OrderPanel
                title='Metal Roof Report'
                subtitle='New'
                rateLabel='from'
                rate={priceGrids.MetalRoof[0].tierPrices[0].price}
                orderType={OrderTypes.MetalRoof}
                enableGutters={enableGutters}
                openPricingPopover={openPricingPopover}
            >
                <OrderFeature text='Detailed measurements' />
                <OrderFeature text='Linear Measurements, Panel Count, & Cut Panel Diagram' />
                <OrderFeature text='Integrated with our estimating platform' />
            </OrderPanel>
        );
    }

    if (areGuttersEnabled) {
        orderList.push(
            <OrderPanel
                title='Gutter Report'
                subtitle='New'
                rateLabel='flat rate'
                rate={priceGrids.Gutters[0].tierPrices[0].price}
                orderType={OrderTypes.Gutters}
                openPricingPopover={openPricingPopover}
            >
                <OrderFeature text='All eaves lengths' />
                <OrderFeature text='Total count for end caps, miters, other' />
                <OrderFeature text='Ideal for estimating gutters' />
            </OrderPanel>
        );
    }

    let gridWidth = classes.smallGrid;
    if (orderList.length === 3) {
        gridWidth = classes.medGrid;
    } else if (orderList.length === 4) {
        gridWidth = classes.lgGrid;
    }

    return (
        <>
            <Stack
                sx={{
                    justifyContent: 'center',
                    pt: 5,
                }}
            >
                <Box ml={{ xs: 2, sm: 20 }}>
                    <Typography
                        variant='h6'
                        sx={{ fontSize: 22, fontWeight: 'bold' }}
                    >
                        New Measurement Order
                    </Typography>
                    <Typography sx={{ fontSize: 20 }}>
                        Select order Type
                    </Typography>
                </Box>
                <Grid container className={gridWidth} spacing={2}>
                    {orderList.map((order) => {
                        let xs: GridSize = 12;
                        let sm: GridSize = 6;
                        let md: GridSize = (12 / orderList.length) as GridSize;
                        return (
                            <Grid item xs={xs} sm={sm} md={md}>
                                {order}
                            </Grid>
                        );
                    })}
                </Grid>
            </Stack>
            <PricingPopover
                open={pricingPopoverOpen}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                priceGridType={pricingType}
            ></PricingPopover>
        </>
    );
};

export default connect(mapPricingStateToProps)(
    withStyles(styles)(OrderLanding)
);

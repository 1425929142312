import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@material-ui/core';
import { LockOutlined } from '@material-ui/icons';
import CardTitle from '../CardTitle';

const style = {
    media: {
        height: '100px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '8px',
    },
    disabled: {
        cursor: 'not-allowed',
        backgroundColor: '#00000095',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
    },
};

const ProjectHomeCards = props => (
    <Card id={props.id} className="projectHomeCard" onClick={props.callBack}>
        <div style={{ position: 'relative', width: '100%' }}>
            <div style={{ display: props.isLocked ? 'block' : 'none', ...style.disabled }}>
                <div style={{
                    position: 'absolute', right: '0', bottom: '13px', left: '0', color: 'white', textAlign: 'center',
                }}
                >
                    <LockOutlined style={{ position: 'absolute', right: '7px', bottom: '0' }} />
                </div>
            </div>
            <div style={{ ...style.media, ...props.innerDivStyle }}>
                {props.children}
            </div>

            <CardTitle title={props.title} padding style={props.isLocked ? { ...style.disabled } : {}} />
        </div>
    </Card>
);

ProjectHomeCards.propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    callBack: PropTypes.func.isRequired,
    innerDivStyle: PropTypes.shape({}),
    isLocked: PropTypes.bool,
};

ProjectHomeCards.defaultProps = {
    innerDivStyle: {},
    isLocked: false,
};

export default ProjectHomeCards;

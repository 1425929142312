import {
    Box,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    styled,
    Typography,
} from '@mui/material';
import React from 'react';
import StatusChip from '../../UI/StatusChip/StatusChip';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const Root = styled(Box)`
    margin-top: 3rem;
`;

const Row = styled(Box)`
    padding: 1.25rem;
    border-radius: 0.75rem;
    border: 1px solid var(--color-grey-200, #d9d9d9);
    background: #fff;
`;

const Title = styled(Typography)`
    font-weight: 500;
    line-height: 1.5rem;
    letter-spacing: 0.00938rem;
    paddingbottom: 0.5rem;
`;

const HeaderCell = styled(Typography)`
    color: var(--color-grey-400, #555);

    font-size: 0.6875rem;
    font-weight: 500;
    line-height: 1rem;
    letter-spacing: 0.03125rem;
    text-transform: uppercase;
`;

const ProjectDetailRow = () => {
    const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(
        null
    );

    const menuOpen = Boolean(menuAnchor);

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchor(event.currentTarget);
    };
    const handleMenuClose = () => {
        setMenuAnchor(null);
    };

    return (
        <Root>
            <Title>Measurements</Title>

            <Stack direction='row' padding='0 3rem 0.5rem 1rem'>
                <Box width='41%'>
                    <HeaderCell>Report</HeaderCell>
                </Box>
                <Box width='22%'>
                    <HeaderCell>Time</HeaderCell>
                </Box>
                <Box width='22%'>
                    <HeaderCell>Status</HeaderCell>
                </Box>
                <Box
                    width='15%'
                    justifyContent='flex-end'
                    sx={{ display: 'flex' }}
                >
                    <HeaderCell>Actions</HeaderCell>
                </Box>
            </Stack>
            <Row>
                <Stack direction='row' alignItems='center'>
                    <Stack
                        direction='row'
                        alignItems='center'
                        gap='0.9375rem'
                        width='40%'
                    >
                        <img
                            src='/assets/project-detail-row-drawing-pending.svg'
                            alt='Drawing'
                        />
                        <Box>
                            <Typography>Detailed Report</Typography>
                            <Typography>1,821 - sq. ft.</Typography>
                        </Box>
                    </Stack>
                    <Box width='22%'>
                        <Typography>10/7/23 - 11:02pm (EST)</Typography>
                    </Box>
                    <Box>
                        <StatusChip status='complete' />
                    </Box>
                    <Box
                        width='30%'
                        justifyContent='flex-end'
                        sx={{ display: 'flex' }}
                    >
                        <IconButton onClick={handleMenuClick}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            open={menuOpen}
                            anchorEl={menuAnchor}
                            onClose={handleMenuClose}
                        >
                            <MenuItem>Edit Diagram</MenuItem>
                            <MenuItem>Get Revision</MenuItem>
                            <MenuItem>Measurements</MenuItem>
                            <MenuItem>Generate Report</MenuItem>
                            <MenuItem>View Report</MenuItem>
                            <MenuItem>View 3-D (Beta)</MenuItem>
                        </Menu>
                    </Box>
                </Stack>
            </Row>
        </Root>
    );
};

export default ProjectDetailRow;

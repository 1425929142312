import React, { ChangeEvent } from 'react';
import {
    withStyles,
    WithStyles,
    createStyles,
    Theme,
} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Hidden from '@material-ui/core/Hidden';
import SvgIcon from '@material-ui/core/SvgIcon';
import { Flip, Chat } from '@material-ui/icons';
import ApartmentIcon from '@material-ui/icons/Apartment';
import BuildingTypes from '../../../lib/BuildingTypes';
import MetalRoofOption from '../../../lib/MetalRoofOption';
import GutterOption from '../../../lib/GutterOption';
import AutocompleteSelect from '../../AutocompleteSelect';
import { ReactComponent as HomeWithGarageIcon } from '../../FirstSketchOrder/home-with-garage.svg';
import { ReactComponent as HomeIcon } from '../../FirstSketchOrder/home.svg';
import OfficeDropdown from './OfficeDropdown/SketchOSOfficeDropdown';
import User from 'lib/Models/User';
import { InputBaseComponentProps } from '@material-ui/core';
import { AllowedOrderTypes, OrderTypes } from 'lib/MeasurementOrders';

const styles = (theme: Theme) =>
    createStyles({
        formControl: {
            margin: '1rem 0',
            marginLeft: 0,
        },
        sketchOptionsSection: {
            paddingTop: '2rem',
        },
        sketchOptionsItem: {
            marginLeft: 12,
            fontSize: 12,
            [theme.breakpoints.down('xs')]: {
                marginLeft: 6,
                fontSize: 12,
            },
        },
        sketchOptionsHeader: {
            fontSize: '14px',
            [theme.breakpoints.down('xs')]: {
                fontSize: 14,
            },
        },
        icon: {
            height: 32,
            width: 32,
            paddingRight: 8,
            paddingLeft: 4,
            marginBottom: -4,
            fill: '#005CAA',
            [theme.breakpoints.down('xs')]: {
                height: 24,
                width: 24,
            },
        },
    });

interface SketchOrderProjectDetailsProps extends WithStyles<typeof styles> {
    users: User[];
    projectName: string;
    notes: string;
    ownerUserId: number;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onOwnerChange: (ownerId: number) => void;
    onSelectedBuildingTypeChange: (buildingType: BuildingTypes) => void;
    onMetalSizeOptionsChange?: (metalOptions: number | undefined) => void;
    onGutterOptionsChange?: (gutterOptions: number | undefined) => void;
    selectedBuildingType: BuildingTypes;
    selectedMetalPanelSize: number | undefined;
    selectedGutterSize: number | undefined;
    showRequiredHelperText?: boolean;
    sketchReportType: AllowedOrderTypes;
    includesGutters: boolean;
}

const SketchOrderProjectDetails: React.FC<SketchOrderProjectDetailsProps> = ({
    classes,
    users,
    projectName,
    notes,
    ownerUserId,
    onChange,
    onOwnerChange,
    onSelectedBuildingTypeChange,
    onMetalSizeOptionsChange,
    onGutterOptionsChange,
    selectedBuildingType,
    showRequiredHelperText = false,
    sketchReportType,
    includesGutters,
    selectedMetalPanelSize,
    selectedGutterSize,
}) => {
    const getUsers = () =>
        users.map((user: User) => ({
            value: user.userId,
            label: user.userName,
        }));

    const handleBuildingTypeChange = (value: BuildingTypes) => {
        if (onSelectedBuildingTypeChange) {
            onSelectedBuildingTypeChange(value);
        }
    };

    const handleMetalOptionChange = (value: number | undefined) => {
        if (onMetalSizeOptionsChange) {
            onMetalSizeOptionsChange(value);
        }
    };

    const handleGutterOptionChange = (value: number | undefined) => {
        if (onGutterOptionsChange) {
            onGutterOptionsChange(value);
        }
    };

    const currentUsers = getUsers();
    return (
        <div>
            <FormControl className={classes.formControl} fullWidth>
                <InputLabel shrink>Project Name</InputLabel>
                <Input
                    id='project-name'
                    type='text'
                    multiline
                    onChange={onChange}
                    value={projectName}
                    name='projectName'
                    placeholder='Enter a name for your project'
                    inputProps={{
                        maxLength: 100,
                    }}
                />
            </FormControl>
            <FormControl
                className={classes.formControl}
                fullWidth
                style={{ zIndex: 1000 }}
            >
                <InputLabel shrink>Owner</InputLabel>
                <Input
                    fullWidth
                    value={ownerUserId}
                    onChange={(e) => onOwnerChange(parseInt(e.target.value))}
                    placeholder='Search email address'
                    id='react-select-single'
                    inputComponent={
                        AutocompleteSelect as React.ElementType<InputBaseComponentProps>
                    }
                    inputProps={{
                        classes,
                        name: 'react-select-single',
                        instanceId: 'react-select-single',
                        simpleValue: true,
                        options: currentUsers,
                        disableItemRemoval: true,
                    }}
                />
            </FormControl>
            <OfficeDropdown
                className={classes.formControl}
                selectedUserId={ownerUserId}
            />
            <div className={classes.sketchOptionsSection}>
                <FormControl
                    id='sketch-options-form'
                    fullWidth
                    className={classes.formControl}
                >
                    <InputLabel shrink>Building Type</InputLabel>
                    <Select
                        value={selectedBuildingType}
                        onChange={(e) =>
                            handleBuildingTypeChange(
                                e.target.value as BuildingTypes
                            )
                        }
                        id='sketch-options'
                        displayEmpty
                        name='sketchOptions'
                    >
                        <MenuItem
                            id='option-none'
                            value={BuildingTypes.None}
                            className={classes.sketchOptionsHeader}
                        >
                            <em>Select an option (Required)</em>
                        </MenuItem>
                        <MenuItem
                            id='option-main-building-only'
                            className={classes.sketchOptionsItem}
                            value={BuildingTypes.MainBuildingOnly}
                        >
                            <HomeIcon className={classes.icon} /> Single Family
                        </MenuItem>
                        <MenuItem
                            id='option-main-building-with-garage'
                            className={classes.sketchOptionsItem}
                            value={BuildingTypes.MainBuildingWithGarage}
                        >
                            <SvgIcon className={classes.icon}>
                                <HomeWithGarageIcon />
                            </SvgIcon>
                            Single Family with Outbuilding
                        </MenuItem>
                        <MenuItem
                            id='option-whole-structure'
                            className={classes.sketchOptionsItem}
                            value={BuildingTypes.WholeStructure}
                        >
                            <ApartmentIcon className={classes.icon} />
                            Commercial/Multifamily
                            <Hidden xsDown>&nbsp;(Whole Building)</Hidden>
                        </MenuItem>
                        <MenuItem
                            id='option-partial-structure'
                            className={classes.sketchOptionsItem}
                            value={BuildingTypes.PartialStructure}
                        >
                            <Flip className={classes.icon} /> Partial Structure
                        </MenuItem>
                        <MenuItem
                            id='option-other'
                            className={classes.sketchOptionsItem}
                            value={BuildingTypes.Other}
                        >
                            <Chat className={classes.icon} /> Other
                        </MenuItem>
                    </Select>
                    {selectedBuildingType === BuildingTypes.None &&
                        showRequiredHelperText && (
                            <FormHelperText id='building-type-warning' error>
                                Select an order option before clicking next
                            </FormHelperText>
                        )}
                </FormControl>

                {sketchReportType === OrderTypes.MetalRoof && (
                    <FormControl
                        id='metal-sheet-options-form'
                        fullWidth
                        className={classes.formControl}
                    >
                        <InputLabel shrink>Metal Panel Size</InputLabel>
                        <Select
                            value={selectedMetalPanelSize}
                            onChange={(e) =>
                                handleMetalOptionChange(
                                    e.target.value as number | undefined
                                )
                            }
                            id='metal-options'
                            displayEmpty
                            name='metalOptions'
                        >
                            {Object.keys(MetalRoofOption).map((key) => {
                                const option = MetalRoofOption[key];
                                return (
                                    <MenuItem
                                        key={key}
                                        value={option.value}
                                        className={
                                            option === MetalRoofOption.None
                                                ? classes.sketchOptionsHeader
                                                : classes.sketchOptionsItem
                                        }
                                    >
                                        {MetalRoofOption[key].label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        {selectedMetalPanelSize === undefined &&
                            showRequiredHelperText && (
                                <FormHelperText id='metal-size-warning' error>
                                    Select a metal roof option before clicking
                                    next
                                </FormHelperText>
                            )}
                    </FormControl>
                )}
                {(includesGutters ||
                    sketchReportType === OrderTypes.Gutters) && (
                    <FormControl
                        id='gutter-options-form'
                        fullWidth
                        className={classes.formControl}
                    >
                        <InputLabel shrink>Gutter Size</InputLabel>
                        <Select
                            value={selectedGutterSize}
                            onChange={(e) =>
                                handleGutterOptionChange(
                                    e.target.value as number | undefined
                                )
                            }
                            id='gutter-options'
                            displayEmpty
                            name='gutterOptions'
                        >
                            {Object.keys(GutterOption).map((key) => {
                                const option = GutterOption[key];
                                return (
                                    <MenuItem
                                        key={key}
                                        value={option.value}
                                        className={
                                            option === GutterOption.None
                                                ? classes.sketchOptionsHeader
                                                : classes.sketchOptionsItem
                                        }
                                    >
                                        {GutterOption[key].label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        {selectedGutterSize === undefined &&
                            showRequiredHelperText && (
                                <FormHelperText id='gutter-size-warning' error>
                                    Select a gutter option before clicking next
                                </FormHelperText>
                            )}
                    </FormControl>
                )}
            </div>

            <FormControl className={classes.formControl} fullWidth>
                <InputLabel shrink>Notes</InputLabel>
                <FilledInput
                    id='order-notes'
                    type='text'
                    multiline
                    onChange={onChange}
                    value={notes}
                    name='notes'
                    placeholder='(i.e. dormer pitch is 6/12, include detached shed, do not include metal awnings, flat section only, etc.)'
                    inputProps={{
                        maxLength: 4000,
                    }}
                />
                {selectedBuildingType === BuildingTypes.Other && (
                    <FormHelperText id='notes-warning' error>
                        Please enter notes for building type "Other"
                    </FormHelperText>
                )}
                {selectedBuildingType === BuildingTypes.PartialStructure && (
                    <FormHelperText id='notes-warning' error>
                        Please enter notes for building type "Partial Structure"
                    </FormHelperText>
                )}
            </FormControl>
        </div>
    );
};

export default withStyles(styles)(SketchOrderProjectDetails);

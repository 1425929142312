import RoofSnapApiClient from '../../../../../middleware/roofSnapApiClient';
import httpRequestMethods from '../../../../../middleware/httpRequestMethods';
import httpStatusCodes from '../../../../../middleware/httpStatusCodes';

export const GET_OFFICES_BY_USER_SOS_REQUEST = 'GET_OFFICES_BY_USER_SOS_REQUEST';
export const GET_OFFICES_BY_USER_SOS_SUCCESS = 'GET_OFFICES_BY_USER_SOS_SUCCESS';
export const GET_OFFICES_BY_USER_SOS_FAILURE = 'GET_OFFICES_BY_USER_SOS_FAILURE';

const getUsersOfficesForSOSRequest = () => ({
    type: GET_OFFICES_BY_USER_SOS_REQUEST,
});

const getUsersOfficesForSOSSuccsess = (offices, selectedUserId) => ({
    type: GET_OFFICES_BY_USER_SOS_SUCCESS,
    offices,
    userId: selectedUserId,
});

const getUsersOfficesForSOSFailure = () => ({
    type: GET_OFFICES_BY_USER_SOS_FAILURE,
});
export const getUsersOfficesForSOS = selectedUserId => async (dispatch) => {
    dispatch(getUsersOfficesForSOSRequest());
    let offices = [];

    try {
        const roofSnapApiClient = new RoofSnapApiClient();
        const userProfilesOfficesResponse = await roofSnapApiClient.callApi({
            endpoint: `userprofiles/${selectedUserId}/offices`,
            method: httpRequestMethods.GET,
            params: {
                page: 1,
            },
            getAllPages: true,
        });

        offices = userProfilesOfficesResponse.resourceList;
    } catch (error) {
        if (error.status !== httpStatusCodes.NotFound) {
            return dispatch(getUsersOfficesForSOSFailure());
        }
    }

    return dispatch(getUsersOfficesForSOSSuccsess(offices, selectedUserId));
};

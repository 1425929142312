import { Box, styled, Typography } from '@mui/material';
import React from 'react';
import { ReactComponent as EllipseSvg } from './Ellipse.svg';

const statusVariants: { [key: string]: { [key: string]: {} } } = {
    complete: {
        root: {
            border: '1px solid #9AE09F',
            background: '#F3FCF4',
        },
        ellipse: {
            fill: 'var(--color-positive-200, #149718)',
        },
        label: {
            color: 'var(--color-positive-200, #149718)',
        },
    },
};

const StatusChip = ({ status }: { status: string }) => {
    const Ellipse = styled(EllipseSvg)(() => ({
        width: '0.5rem',
        height: '0.5rem',
        ...(statusVariants[status] && statusVariants[status].ellipse),
    }));

    const Root = styled(Box)(() => ({
        display: 'flex',
        height: '2rem',
        padding: '0rem 0.1875rem 0rem 0.625rem',
        alignItems: 'center',
        borderRadius: '0.5rem',
        ...(statusVariants[status] && statusVariants[status].root),
    }));

    const StatusContainer = styled(Box)`
        display: flex;
        padding: 0.3125rem 0.625rem 0.3125rem 0.5rem;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
    `;

    const StatusLabel = styled(Typography)(() => ({
        textAlign: 'right',
        fontFamily: 'Roboto',
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: 'normal',
        letterSpacing: '0.00625rem',
        textTransform: 'capitalize',
        ...(statusVariants[status] && statusVariants[status].label),
    }));

    return (
        <Root>
            <Ellipse />
            <StatusContainer>
                <StatusLabel>{status}</StatusLabel>
            </StatusContainer>
        </Root>
    );
};

export default StatusChip;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MobileStepper from '@material-ui/core/MobileStepper';
import Toolbar from '@material-ui/core/Toolbar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ApartmentIcon from '@material-ui/icons/Apartment';
import queryString from 'query-string';
import LoggerWrapper from '../../lib/Logger';
import FirstSketchOrderDetails from './FirstSketchOrderDetails';
import FirstSketchOrderConfirmation from './FirstSketchOrderConfirmation/FirstSketchOrderConfirmation';
import BuildingTypes from '../../lib/BuildingTypes.ts';
import Location from '../SketchOS/Order/Location';
import {
    geocodeAddress,
    updateMarker,
} from '../../actions/SketchOsGeocodingActions';
import {
    CREATE_BULK_SKETCH_ORDER_SUCCESS,
    createSketchOrders,
} from '../../actions/SketchOrderActions';
import PAYMENT_TYPE from '../../lib/PaymentType.ts';
import { setReduxMessage } from '../../actions/ReduxMessagesActions';
import { getOrganization } from '../../actions/Organization';
import {
    getOrganizationsFreeSketchOrdersBalance,
    getOrganizationOpenSketchOrders,
} from '../../actions/OrganizationFreeSketchOrdersActions';
import { ReactComponent as HomeWithGarageIcon } from './home-with-garage.svg';
import { ReactComponent as HomeIcon } from './home.svg';
import TileSelector from '../Tile/TileSelector/TileSelector';
import { ReactComponent as RoofSnapLogo } from '../../roofsnap-logo.svg';
import PaymentCollectionDialog from '../PaymentCollectionDialog/PaymentCollectionDialog';

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        position: 'fixed',
        top: 0,
        height: '100%',
        width: '100vw',
        zIndex: theme.zIndex.appBar + 1,
        overflowY: 'overlay',
    },
    gridContainer: {
        justifyContent: 'center',
        backgroundColor: '#ffffff',
        paddingLeft: `${theme.spacing(2)}px`,
        paddingRight: `${theme.spacing(2)}px`,
        width: '100%',
        minHeight: '100vh',
        borderRadius: 0,
        margin: 0,
        [theme.breakpoints.down('xs')]: {
            paddingBottom: `${
                theme.mixins.toolbar.minHeight + theme.spacing(4)
            }px`,
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: 'auto',
            marginRight: 'auto',
            left: 0,
            right: 0,
            paddingBottom: `${
                theme.mixins.toolbar.minHeight + theme.spacing(4)
            }px`,
            maxWidth: 860,
            boxShadow: theme.shadows[1],
        },
    },
    paperContainer: {
        position: 'fixed',
        bottom: 0,
        boxSizing: 'border-box',
        zIndex: theme.zIndex.appBar + 1,
        boxShadow: 'none',
        borderRadius: 0,
        border: `solid 1px ${theme.palette.secondary.A200}`,
        borderBottom: 'none',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            width: 500,
            marginLeft: 'auto',
            marginRight: 'auto',
            left: 0,
            right: 0,
            boxShadow: theme.shadows[1],
        },
    },
    mapInfo: {
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
        borderBottom: `solid 1px ${theme.palette.secondary.A200}`,
    },
    stepperContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    stepper: {
        backgroundColor: 'transparent',
        width: 500,
        padding: 0,
        boxSizing: 'border-box',
    },
    stepperDotActive: {
        backgroundColor: theme.palette.secondary.A200,
    },
    mapContainer: {
        position: 'fixed',
        top: 0,
        height: '100vh',
        width: '100vw',
        zIndex: theme.zIndex.appBar + 1,
    },
    searchInput: {
        border: 0,
        flex: 1,
    },
    button: {
        width: 90,
    },
    icons: {
        width: 48,
        height: 48,
        fill: '#2d6aff',
    },
    roofSnapLogo: {
        height: 48,
        paddingTop: `${theme.spacing(2)}px`,
        [theme.breakpoints.down('md')]: {
            height: 36,
        },
    },
});

class FirstSketchOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 0,
            zoom: 3,
            center: {
                lat: 38,
                lng: -97,
            },
            notes: '',
            selectedSketchOption: this.props.defaultSelectedSketchOptions,
            showSuccessDialog: false,
            showOptionSelectDialog: false,
            showPaymentDialog: false,
            paymentType: '',
            paymentValid: false,
            address: '',
            city: '',
            region: '',
            postcode: '',
            country: '',
            addressValid: false,
            returnToConfirmationPage: false,
            addressChanged: false,
        };
    }

    async componentDidMount() {
        const queryParams = queryString.parse(this.props.location.search);
        this.props.dispatch(geocodeAddress(queryParams.address));
        this.getFreeSketchOrders();
    }

    componentDidUpdate(prevProps) {
        if (
            (this.state.zoom === 3 && this.props.markers.length > 0) ||
            this.didAddressChange(prevProps)
        ) {
            this.centerMarker(this.props.markers[0]);
        }
        if (
            !this.state.paymentType ||
            this.didSketchOrderBalancesUpdate(prevProps)
        )
            this.determinePaymentType();
    }
    onPaymentChange = (paymentValid, getNonce) => {
        this.setState({
            paymentValid,
            getNonce,
        });
    };
    getFreeSketchOrders() {
        this.props.dispatch(getOrganization(this.props.userId));
        this.props.dispatch(
            getOrganizationsFreeSketchOrdersBalance(this.props.organizationId)
        );
        this.props.dispatch(
            getOrganizationOpenSketchOrders(this.props.organizationId)
        );
    }
    determinePaymentType() {
        const userHasFreeOrdersAvailable =
            this.props.freeSketchOrderBalance > this.props.openSketchOrders;
        if (
            userHasFreeOrdersAvailable &&
            this.state.paymentType !== PAYMENT_TYPE.NONE
        ) {
            this.setState({
                paymentType: PAYMENT_TYPE.NONE,
            });
            return;
        }
        if (
            !userHasFreeOrdersAvailable &&
            this.state.paymentType !== PAYMENT_TYPE.USER
        ) {
            this.setState({
                paymentType: PAYMENT_TYPE.USER,
            });
        }
    }
    firstSketchOrderTiles = [
        {
            id: BuildingTypes.MainBuildingOnly,
            title: 'Single Family',
            icon: <HomeIcon className={this.props.classes.icons} />,
        },
        {
            id: BuildingTypes.MainBuildingWithGarage,
            title: 'Single Family w/ Detached Garage',
            icon: <HomeWithGarageIcon className={this.props.classes.icons} />,
        },
        {
            id: BuildingTypes.WholeStructure,
            title: 'Commercial / Multi-Family Structure',
            icon: <ApartmentIcon className={this.props.classes.icons} />,
        },
    ];
    handlePinConfirmed = () => {
        if (!this.state.returnToConfirmationPage || this.state.addressChanged) {
            this.setState({
                address: this.props.address.addressLine1,
                city: this.props.address.city,
                region: this.props.address.state,
                postcode: this.props.address.postalCode,
                country: this.props.address.country,
                addressChanged: false,
            });
        }

        if (this.state.returnToConfirmationPage) {
            this.handleGoToStep(2);
        } else {
            this.handleNext();
        }
    };
    handleBack = () => {
        if (this.state.step > 0) {
            this.setState({
                step: this.state.step - 1,
            });
        }
    };
    handleCancel = () => {
        this.props.history.push('/projects');
    };
    handleNext = () => {
        this.setState({
            step: this.state.step + 1,
        });
    };
    handleGoToStep = (stepNumber) => {
        this.setState({
            step: stepNumber,
        });
    };
    handleOrderSuccess = () => {
        try {
            window.Appcues.track('Created first Sketch Order');
        } catch (error) {
            LoggerWrapper.captureException(error);
        }
        this.setState({
            showSuccessDialog: true,
        });
    };
    async createSketchOrder() {
        const newSketchOrder = {
            organizationId: this.props.organizationId,
            address: this.state.address,
            city: this.state.city,
            region: this.state.region,
            postcode: this.state.postcode,
            country: this.state.country,
            projectName: this.state.address,
            ownerUserId: this.props.currentUserId,
            notes: this.state.notes,
            location: {
                coordinates: [
                    this.props.markers[0].coordinates.lng,
                    this.props.markers[0].coordinates.lat,
                ],
                type: 'Point',
            },
            requesterUserId: this.props.currentUserId,
            sketchReportType: 'FullSnap',
            sketchOptions: [this.state.selectedSketchOption],
        };
        if (this.state.paymentType === PAYMENT_TYPE.USER) {
            const nonce = await this.state.getNonce();
            return this.props.dispatch(
                createSketchOrders([newSketchOrder], PAYMENT_TYPE.USER, nonce)
            );
        }
        return this.props.dispatch(
            createSketchOrders([newSketchOrder], PAYMENT_TYPE.NONE)
        );
    }
    handleSubmitButtonClicked = () => {
        const userPaymentValid =
            this.state.paymentType === PAYMENT_TYPE.USER &&
            this.state.paymentValid;
        const userCanSubmit =
            this.state.addressValid &&
            (userPaymentValid || this.state.paymentType === PAYMENT_TYPE.NONE);
        if (
            this.state.paymentType === PAYMENT_TYPE.USER &&
            !this.state.paymentValid
        ) {
            this.props.dispatch(
                setReduxMessage(
                    'You must enter a valid credit card to place this order'
                )
            );
            this.setState({
                showPaymentDialog: true,
            });
            return;
        }
        if (!this.state.addressValid) {
            this.props.dispatch(
                setReduxMessage(
                    'You must enter a complete address to submit this order'
                )
            );
            return;
        }
        if (userCanSubmit) {
            this.handleSubmit();
        }
    };
    handleSubmit = async () => {
        const result = await this.createSketchOrder();
        if (result.type === CREATE_BULK_SKETCH_ORDER_SUCCESS) {
            this.handleOrderSuccess();
        } else {
            this.props.dispatch(
                setReduxMessage(
                    'Sorry. It looks like something went wrong trying to create your free sketch order. Please contact support at (877) 766-3762'
                )
            );
        }
    };
    handleOnChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };
    handleUpdateSketchOption = (selectedSketchOption) => {
        this.setState({
            selectedSketchOption,
        });
        if (this.state.showOptionSelectDialog === true)
            this.setState({ showOptionSelectDialog: false });
    };
    centerMarker = (marker) => {
        this.setState({
            zoom: 20,
            center: marker.coordinates,
        });
    };
    handleMarkerUpdated = (marker) => {
        this.props.dispatch(updateMarker(marker));
    };
    handleCenterChanged = (center) => {
        if (this.props.markers.length === 0) return;
        this.setState({
            center: {
                lat: center.lat(),
                lng: center.lng(),
            },
        });
    };
    handleAddressChanged = (address) => {
        this.setState({
            addressChanged: true,
        });
        this.props.dispatch(geocodeAddress(address));
    };
    didAddressChange = (prevProps) =>
        prevProps.address.addressLine1 !== this.props.address.addressLine1 ||
        prevProps.address.city !== this.props.address.city ||
        prevProps.address.state !== this.props.address.state ||
        prevProps.address.postalCode !== this.props.address.postalCode ||
        prevProps.address.country !== this.props.address.country;
    didSketchOrderBalancesUpdate = (prevProps) =>
        prevProps.freeSketchOrderBalance !==
            this.props.freeSketchOrderBalance ||
        prevProps.openSketchOrders !== this.props.openSketchOrders;
    handleZoomChanged = (zoom) => {
        if (this.props.markers.length === 0) {
            return;
        }

        this.setState({ zoom });
    };
    handleAddressFormValidate = (isFormValid) => {
        this.setState({ addressValid: isFormValid });
    };
    handlePinConfirmationMapClicked = () => {
        this.setState(
            { returnToConfirmationPage: true },
            this.handleGoToStep(0)
        );
    };
    handleSketchOptionConfirmationTileClicked = () => {
        this.setState({ showOptionSelectDialog: true });
    };
    renderStepperNextButton = (step) => {
        if (step === 0) {
            return (
                <Button
                    variant='contained'
                    color='secondary'
                    className={this.props.classes.button}
                    onClick={this.handlePinConfirmed}
                >
                    Yes
                </Button>
            );
        }
        if (step === 2) {
            return (
                <Button
                    variant='contained'
                    color='secondary'
                    className={this.props.classes.button}
                    onClick={this.handleSubmitButtonClicked}
                >
                    Submit
                </Button>
            );
        }
        return (
            <Button
                variant='contained'
                color='secondary'
                className={this.props.classes.button}
                onClick={this.handleNext}
            >
                Next
            </Button>
        );
    };

    render() {
        return (
            <Paper className={this.props.classes.root}>
                <Grid
                    container
                    item
                    justify='center'
                    alignContent='flex-start'
                    className={this.props.classes.gridContainer}
                    spacing={2}
                >
                    <Grid item>
                        <RoofSnapLogo
                            className={this.props.classes.roofSnapLogo}
                        />
                    </Grid>
                    {this.state.step === 0 && (
                        <div className={this.props.classes.mapContainer}>
                            <Location
                                // TODO: this is just a placeholder tooltip
                                markers={this.props.markers.map((marker) => ({
                                    ...marker,
                                    infoWindowContent: (
                                        <div>
                                            Drag this pin onto the structure
                                            <br />
                                            you would like measured.
                                        </div>
                                    ),
                                }))}
                                onAddressChanged={this.handleAddressChanged}
                                onMarkerUpdated={this.handleMarkerUpdated}
                                onCenterChanged={this.handleCenterChanged}
                                onZoomChanged={this.handleZoomChanged}
                                center={this.state.center}
                                zoom={this.state.zoom}
                                multiMarkerDisabled
                                renderContainer={
                                    <div style={{ height: '100%' }} />
                                }
                                loadingElement={<CircularProgress />}
                                containerElement={
                                    <div style={{ height: '100%' }} />
                                }
                                mapElement={
                                    <div
                                        id='googleMap'
                                        style={{ height: '100%' }}
                                    />
                                }
                            />
                        </div>
                    )}
                    {this.state.step === 1 && (
                        <FirstSketchOrderDetails
                            firstSketchOrderTiles={this.firstSketchOrderTiles}
                            selectedSketchOption={
                                this.state.selectedSketchOption
                            }
                            handleUpdateSketchOption={
                                this.handleUpdateSketchOption
                            }
                            notes={this.state.notes}
                            handleOnChange={this.handleOnChange}
                        />
                    )}
                    {this.state.step === 2 && (
                        <FirstSketchOrderConfirmation
                            handleGoToStep={this.handleGoToStep}
                            handleOnChange={this.handleOnChange}
                            handleFormValidate={this.handleAddressFormValidate}
                            handlePinConfirmationMapClicked={
                                this.handlePinConfirmationMapClicked
                            }
                            handleSketchOptionConfirmationTileClicked={
                                this.handleSketchOptionConfirmationTileClicked
                            }
                            selectedSketchOption={
                                this.state.selectedSketchOption
                            }
                            notes={this.state.notes}
                            markerLocation={{
                                lat: this.props.markers[0].coordinates.lat,
                                lng: this.props.markers[0].coordinates.lng,
                            }}
                            address={this.state.address}
                            city={this.state.city}
                            region={this.state.region}
                            postcode={this.state.postcode}
                            country={this.state.country}
                        />
                    )}

                    <Paper className={this.props.classes.paperContainer}>
                        {this.state.step === 0 && (
                            <Typography
                                className={this.props.classes.mapInfo}
                                align='center'
                                variant='body1'
                                color='textSecondary'
                            >
                                Is the pin on the correct location?
                            </Typography>
                        )}
                        <Toolbar
                            className={this.props.classes.stepperContainer}
                        >
                            <MobileStepper
                                steps={3}
                                position='static'
                                activeStep={this.state.step}
                                classes={{
                                    root: this.props.classes.stepper,
                                    dotActive:
                                        this.props.classes.stepperDotActive,
                                }}
                                backButton={
                                    this.state.step === 0 ? (
                                        <Button
                                            variant='outlined'
                                            color='secondary'
                                            className={
                                                this.props.classes.button
                                            }
                                            onClick={this.handleCancel}
                                        >
                                            Cancel
                                        </Button>
                                    ) : (
                                        <Button
                                            variant='outlined'
                                            color='secondary'
                                            className={
                                                this.props.classes.button
                                            }
                                            onClick={this.handleBack}
                                        >
                                            Back
                                        </Button>
                                    )
                                }
                                nextButton={this.renderStepperNextButton(
                                    this.state.step
                                )}
                            />
                        </Toolbar>
                    </Paper>
                    <Dialog
                        open={this.state.showSuccessDialog}
                        disableBackdropClick
                        disableEscapeKeyDown
                        onClose={() => this.props.history.push('/orders')}
                    >
                        <DialogTitle
                            id='sketchos-success-dialog'
                            style={{ textAlign: 'center' }}
                        >
                            Order Submitted!
                        </DialogTitle>
                        <DialogContent style={{ textAlign: 'center' }}>
                            <CheckCircleIcon
                                style={{
                                    flex: 1,
                                    fill: this.props.theme.palette.primary[
                                        '500'
                                    ],
                                    width: 100,
                                    height: 100,
                                }}
                            />
                            <DialogContentText style={{ textAlign: 'left' }}>
                                {/* TODO: These <p>'s throwing console warning. Need to be replaced with <Typography>'s */}
                                <Typography variant='subtitle2'>
                                    This first order’s on us, additional orders
                                    start at $10.
                                </Typography>
                                <Typography variant='body1'>
                                    When your order is completed, you’ll receive
                                    an email notification and the completed
                                    project will appear on your projects page.
                                </Typography>
                                <Typography variant='body1'>
                                    Most orders take about 2 to 4 business
                                    hours.
                                </Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions style={{ justifyContent: 'center' }}>
                            <Button
                                variant='contained'
                                color='secondary'
                                onClick={() =>
                                    this.props.history.push('/projects')
                                }
                            >
                                View Projects Page
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={this.state.showOptionSelectDialog}
                        onClose={() =>
                            this.setState({
                                showOptionSelectDialog: false,
                            })
                        }
                    >
                        <DialogContent>
                            <TileSelector
                                tiles={this.firstSketchOrderTiles}
                                selectedTileId={this.state.selectedSketchOption}
                                handleTileSelect={this.handleUpdateSketchOption}
                            />
                        </DialogContent>
                    </Dialog>
                    <PaymentCollectionDialog
                        currentUserId={this.props.currentUserId}
                        open={this.state.showPaymentDialog}
                        memo='Sketch Order prices range from $10 - $45 depending on house size and complexity'
                        onClose={() =>
                            this.setState({
                                showPaymentDialog: false,
                            })
                        }
                        onPaymentChange={this.onPaymentChange}
                        onSubmit={this.handleSubmitButtonClicked}
                    />
                </Grid>
            </Paper>
        );
    }
}

FirstSketchOrder.defaultProps = {
    defaultSelectedSketchOptions: BuildingTypes.MainBuildingOnly,
};

const mapStateToProps = (state) => {
    const {
        sketchOsGeocoding,
        currentUser,
        organization,
        organizationFreeSketchOrders,
    } = state;
    const { address, markers } = sketchOsGeocoding;
    const { userId, organizationId } = currentUser;
    const { freeSketchOrderBalance, openSketchOrders } =
        organizationFreeSketchOrders;
    return {
        address,
        markers,
        currentUserId: userId,
        organization,
        organizationId,
        freeSketchOrderBalance,
        openSketchOrders,
    };
};

export default connect(mapStateToProps)(
    withStyles(styles, { withTheme: true })(FirstSketchOrder)
);

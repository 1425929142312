import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import PaymentStep from '../SketchOS/Order/PaymentStep.tsx';

class RetrySketchOrderBillingDialog extends Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogContent>
                    <PaymentStep
                        onPaymentChange={this.props.onPaymentChange}
                        organization={this.props.organization}
                        currentUser={this.props.currentUser}
                        currentUserRoles={this.props.currentUserRoles}
                        displayUserBillingOnly
                        errorMessage={this.props.errorMessage}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={this.props.onRetryBilling}
                        color="primary"
                        disabled={this.props.retryButtonDisabled || this.props.isRetryingBilling}
                    >
                        Retry
                        {
                            this.props.isRetryingBilling &&
                            <CircularProgress />
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

RetrySketchOrderBillingDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onPaymentChange: PropTypes.func.isRequired,
    onRetryBilling: PropTypes.func.isRequired,
    retryButtonDisabled: PropTypes.bool.isRequired,
    organization: PropTypes.shape({
        isFreeAccount: PropTypes.bool,
    }).isRequired,
    currentUser: PropTypes.shape({
        userId: PropTypes.number.isRequired,
    }).isRequired,
    currentUserRoles: PropTypes.shape({
        sketchosEnabled: PropTypes.bool.isRequired,
    }).isRequired,
    errorMessage: PropTypes.string,
    isRetryingBilling: PropTypes.bool,
};

RetrySketchOrderBillingDialog.defaultProps = {
    errorMessage: null,
    isRetryingBilling: false,
};

export default RetrySketchOrderBillingDialog;
